import styled, { css } from "styled-components";
import Link from "../../UIcomponents/Link.jsx";
import Check from "../../../images/check.png";
import Button from "../../UIcomponents/Button.jsx";
import { useNavigate } from 'react-router-dom';
const ResetSuccess = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/login');
  };

    return (
    <StyledMain>
         <Image src={Check} />
      <Question> Reset Success</Question>
      <Description>
        Your password has been successfully updated.
      </Description>
      <WrapText>
        <AlignButton>
       <Button type="submit" size="medium" isDisabled={false} onClick={handleNavigate}>
        Go to Login
        </Button>
        </AlignButton>
       </WrapText>
     </StyledMain>
  );
};
 
const AlignButton = styled.div`
  margin-top: 35px;
  `;
const WrapText = styled.div`
margin-top: -40px;
`;
const Image = styled.img`
    width: 130px; /* Adjust size as needed */
    padding-right: 15px;
    margin-top: -5px;
`;
const Question = styled.p`
  color: #000000;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 8px;
  width: 314px;
  height: 52px;
  font-size: 60px;
  font: 600 40px BR Omny, sans-serif;
`;
const Description = styled.p`
  color: #111111;
  font-weight: 400px;
  text-align: center;
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.48px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
  height: 21px;
`;
 
const StyledMain = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  height: 456px;
  width: 571px;
  flex-direction: column;
  align-items: center;
  padding: 44px 40px 80px;
  height: 388px;
  @media (max-width: 991px) {
    padding: 70px;
    border-radius: 40px;
  }
`;
export default ResetSuccess;