export const generateAppleMapsToken = async () => {
    try { 
        const url = `https://maps-api.apple.com/v1/token`

        const response = await fetch(url, {method: "GET", headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_MAP_KIT_TOKEN}`,
       
        } });
        const data = await response.json();
       
        return data;
      } catch (error) {
        console.log('Error post data:', error);
        return null;
      }
}

export const getMapKitAddress = async (token, searchValue) => {
    try { 
        const url = `https://maps-api.apple.com/v1/search?q=${searchValue}`

        const response = await fetch(url, {method: "GET", headers: {
          'Authorization': `Bearer ${token}`,
        } });
        const data = await response.json();
       
        return data;
      } catch (error) {
        console.log('Error post data:', error);
        return null;
      }
}