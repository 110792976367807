import React from 'react';
import styled from 'styled-components';
import Sidebar from '../components/dashboard/Sidebar';
import MainContent from '../components/dashboard/MainContent';

const DashboardContainer = styled.div`
  display: flex;
  background-color: #fcf8f4;
  min-height: 100vh;
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <Sidebar />
      <MainContent />
    </DashboardContainer>
  );
};

export default Dashboard;