import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const StyledRouterLink = styled(RouterLink)`
  color: #ff746e;
  font-family: BR Omny, sans-serif;
  font-weight: 500;
  margin-top: 16px;
  cursor: pointer;
  text-decoration: none;
`;
const Link = ({ children, href, onClick }) => (
  <StyledRouterLink to={href} onClick={onClick}>
    {children}
  </StyledRouterLink>
);

export default Link;