import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fill = keyframes`
  0% {
    stroke-dashoffset: 126; /* Length of the circumference */
  }
  50% {
    stroke-dashoffset: 63;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const LoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

const MainSVG = styled.svg`
  position: absolute;
  width: 48px;
  height: 48px;
`;

const FillSVG = styled.svg`
  position: absolute;
  width: 48px;
  height: 48px;
  animation: ${rotate} 2s linear infinite;
`;

const CirclePath = styled.circle`
  fill: none;
  stroke: #FF746E;
  stroke-width: 7;
  stroke-dasharray: 126;
  stroke-dashoffset: 126;
  animation: ${fill} 1.5s ease-in-out infinite;
`;

const Loader = () => (
  <LoaderWrapper>
    <MainSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM7.0804 24C7.0804 33.3444 14.6556 40.9196 24 40.9196C33.3444 40.9196 40.9196 33.3444 40.9196 24C40.9196 14.6556 33.3444 7.0804 24 7.0804C14.6556 7.0804 7.0804 14.6556 7.0804 24Z" fill="#FF746E" fill-opacity="0.4"/>
    </MainSVG>
    <FillSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <CirclePath cx="24" cy="24" r="20" />
    </FillSVG>
  </LoaderWrapper>
);

export default Loader;
