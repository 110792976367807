/* eslint-disable */
import React, { useContext, useState } from "react";

import * as signStyleComponent from "./signStyleComponent";
import { resetPassword } from "../../../services/auth";
import { isJson } from "../../../utils/transformApiErrors";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from 'react-router-dom';
import bcrypt from 'bcryptjs'
import styled, { css } from "styled-components";
import { Link, Link as RouterLink } from "react-router-dom";
import ResetSuccess from "../onboarding/ResetSuccess";
import { hash } from "crypto";


const ResetPasswordForm = () => {
    const [resetPasswordData, setResetPasswordData] = useState({
        confirmPassword: "",
        password: ""
    });
    const [isPasswordChanged, setPasswordChanged] = useState(false);
    const { confirmPassword, password } = resetPasswordData;
    const [registerApiValidatorResponse, setRegisterApiValidator] = useState({});
    const navigate = useNavigate();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [loading, setLoading] = useState(false); // Add loading state
    const [isResetSuccessful, setIsResetSuccessful] = useState(false);

    const updatePassword = async () => {
        setLoading(true);
  
        if (password === confirmPassword) {
            const token = query.get('token');
            const hashedPassword = await bcrypt.hash(password, 12);
            let resPasswordData = await resetPassword({
                token: token,
                password: hashedPassword
            })
            if (resPasswordData.code === 200) {
                setPasswordChanged(true)
                setIsResetSuccessful(true);
                return;
            }
            if (resPasswordData.code === 500 || resPasswordData.code === 400 || resPasswordData.code === 401) {
                let validatorData;
                setLoading(false);
                if (isJson(resPasswordData.message)) {
                    let parsedData = JSON.parse(resPasswordData.message)
                    setRegisterApiValidator(parsedData);
                    validatorData = parsedData
                } else {
                    validatorData = {
                        details: resPasswordData.message || "Server Error"
                    }
                }
                toast.error(validatorData.details || validatorData.email, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            setRegisterApiValidator({
                password: "Password isn't the same!"
            });
            setLoading(false);
            toast.error("Password isn't the same!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        isResetSuccessful ? (
          <ResetSuccess/>
        ) : (
          <StyledMain>
              <ToastContainer />
              <PasswordTittle>
                {isPasswordChanged ? 'Password Changed' : 'Reset Password'}
              </PasswordTittle>
              <Text>
                {isPasswordChanged ? 'Your password has been successfully changed!' : 'Enter and confirm your new password.'}
              </Text>
              {!isPasswordChanged && (
                <div style={{ margin: '20px' }}>
                  <AlignInput>
                    <PasswordInput
                      onChange={(e) =>
                        setResetPasswordData({
                          ...resetPasswordData,
                          password: e.target.value
                        })
                      }
                      validation={registerApiValidatorResponse}
                      value={password}
                      itemName="password"
                      onKeyUp={(e) => delete registerApiValidatorResponse['password']}
                      type="password"
                      placeholder="Enter new password"
                    />
                    <PasswordInput
                      onChange={(e) =>
                        setResetPasswordData({
                          ...resetPasswordData,
                          confirmPassword: e.target.value
                        })
                      }
                      validation={registerApiValidatorResponse}
                      value={confirmPassword}
                      itemName="password"
                      onKeyUp={(e) => delete registerApiValidatorResponse['password']}
                      type="password"
                      placeholder="Re-enter new password"
                    />
                  </AlignInput>
                </div>
              )}
              {isPasswordChanged ? (
                <SubmitButton onClick={() => navigate('/login')}>
                  Back to Login
                </SubmitButton>
              ) : (
                <SubmitButton 
                  disabled={confirmPassword.length < 8 || password.length < 8} 
                  onClick={(e) => { e.preventDefault(); updatePassword(); }}
                >
                  <ButtonContent>
                    {loading ? <Loader /> : "Reset password"}
                  </ButtonContent>
                </SubmitButton>
              )}
          </StyledMain>
        )
      );
      
};

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AlignInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Loader = styled.div`
  border: 4px solid rgb(246 255 246 / 27%);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  left: 40%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Text = styled.p`
    widht: 400px;
    height: 21px;
    font-family: BR Omny, sans-serif;
    font-size: 16px;
    text-align: center;
    margin-top: 23px;
    margin-bottom: 20px;
`;

const PasswordTittle = styled.p`
    width: 314px;
    height: 52px;
    font-family: BR Omny, sans-serif;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
`;
const PasswordInput = styled.input`
 font-family: BR Omny, sans-serif;
  border-radius: 10px;
  border: 1px solid rgba(228, 228, 228, 1);
  background-color: #fcfcfc;
  align-items: center;
  width: 400px;
  margin-top: -13px;
  color: #848484;
  font-weight: 400;
  padding: 16px;
  margin-bottom: -15px;
  height: 51px; 
  margin-bottom: 21px; /* Add margin-bottom for spacing between inputs */

  @media (max-width: 991px) {
    padding: 16px 20px 16px 16px;
    
    
`;

const SubmitButton = styled(RouterLink)`
  font-family: BR Omny, sans-serif;
  height: 456;
  text-align: center;
  border-radius: 10px;
  background: #ff746e;
  margin-top: -24px;
  color: #fff;
  font-weight: 500;
  padding: 16px 40px;
  border: none;
  cursor: pointer;
  transition: 0.9s;
  width: 400px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  position: relative; /* Add relative positioning for the Loader */
  margin-top: -25px;
  @media (max-width: 991px) {
    padding: 16px 20px;
  }
`;

const StyledMain = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  height: 456px;
  top: 138px;
  left: 471px;
  width: 571px;
  flex-direction: column;
  align-items: center;
  padding: 80px 40px 60px;
  height: 468px;


  @media (max-width: 991px) {
    padding: 70px;
    border-radius: 40px;
  }
`;

export default ResetPasswordForm;