import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import grid from '../../images/icon-dashboard-black.svg';
import gridActive from '../../images/icon-dashboard-coral.svg';
import filmVideo from '../../images/film-03.svg';
import filmVideoActive from '../../images/film-031.svg';
import userProfile from '../../images/user-circle-01.svg';
import userProfileActive from '../../images/user-circle.svg';
import settings from '../../images/settings-01.svg';
import settingsActive from '../../images/settings-011.svg';
import logout from '../../images/log-in-01.svg';
import logoutActive from '../../images/log-in-011.svg';

const SidebarWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1199px) {
    position: fixed;
    top: 30px;
    left: ${({ isOpen }) => (isOpen ? '0' : '-275px')};
    transition: left 0.3s ease;
    z-index: 1000;
  }

  @media screen and (max-width: 1199px) {
    margin-left: 20px;

  }
`;

const SidebarContainer = styled.div`
  width: 275px;
  background-color: #fff;
  padding: 30px 16px;
  border-radius: 40px;
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: auto;

  @media screen and (min-width: 1200px) and (max-width: 1365px) {
    width: 215px;
  }

  @media screen and (max-width: 1199px) {
    width: 225px;
  }

    @media screen and (max-width: 1199px) {
         border: 1px solid #ff746e;
  }

`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #111;
  text-decoration: none;
  font-family: BR Omny, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.48px;

  &.active {
    background-color: #fff4f4;
    color: #ff746e;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &.unclickable {
    pointer-events: none;

    img {
      filter: grayscale(100%);
    }
  }
`;

const SettingsNavItem = styled(NavItem)`
  margin-top: auto;
`;

const LogOutNavItem = styled(NavItem)``;

const HamburgerButton = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 21px;
  display: none;
  z-index: 1100;
  cursor: pointer;

  @media screen and (max-width: 1199px) {
    display: block;
  }

  div {
    width: 100%;
    height: 3px;
    background-color: #000;
    margin: 4px 0;
    transition: all 0.3s ease;

    &:nth-child(1) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'none')};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg) translate(5px, -5px)' : 'none')};
    }
  }
`;

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HamburgerButton onClick={toggleSidebar} isOpen={isOpen}>
        <div></div>
        <div></div>
        <div></div>
      </HamburgerButton>
      <SidebarWrapper isOpen={isOpen}>
        <SidebarContainer>
          <NavItemsContainer>
            <NavItem to="/dashboard" exact activeClassName="active">
              <img src={location.pathname === '/dashboard' ? gridActive : grid} alt="Dashboard" />
              Dashboard
            </NavItem>
            <NavItem to="/video-ad-pitches">
              <img src={location.pathname === '/video-ad-pitches' ? filmVideoActive : filmVideo} alt="Video-Ad Pitches" />
              Video-Ad Pitches
            </NavItem>
            <NavItem to="/profile">
              <img src={location.pathname === '/profile' ? userProfileActive : userProfile} alt="Profile" />
              Profile
            </NavItem>
          </NavItemsContainer>
          <SettingsNavItem to="/account">
            <img src={location.pathname === '/account' ? settingsActive : settings} alt="Account" />
            Account
          </SettingsNavItem>
          <LogOutNavItem to="/logout">
            <img src={location.pathname === '/logout' ? logoutActive : logout} alt="LogOut" />
            Log Out
          </LogOutNavItem>
        </SidebarContainer>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
