import React, { forwardRef, useImperativeHandle, useRef, useState, useContext } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselLaunchCard from './CarouselLaunchCard';
import DeleteVideoConfirmation from './DeleteVideoPitch';
import { AppContext } from '../../context/AppContext';

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  @media (max-width: 1500px) {
    max-width: 1000px;  /* Adjust width for screens less than 1500px */
  }

  @media (max-width: 1024px) {
    max-width: 900px;  /* Adjust width for tablet screens */
  }

  @media screen and (max-width: 991px) {
    width: 800px;
  }

  @media (max-width: 768px) {
    max-width: 700px;  /* Adjust width for tablet screens */
  }



  .slick-prev, .slick-next {
    display: none !important;
  }
`;

const Slide = styled.div`
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
`;

const Carousel = forwardRef(({ updateStateIndex, currentStateIndex, onSelectCard, isDialogOpen, onOpen, onClose, onEditActionClicked, initialSlide = 0, carouselData, deleteEvent }, ref) => {
  const sliderRef = useRef(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(initialSlide);
  const { appState } = useContext(AppContext);
  const [selectedItem, setSelectedItem] = useState("");
  const [visiblePopupIndex, setVisiblePopupIndex] = useState(null);

  const isCarouselInfinite = carouselData.length > 4;

  useImperativeHandle(ref, () => ({
    slickPrev: () => sliderRef.current.slickPrev(),
    slickNext: () => sliderRef.current.slickNext(),
    getSlides: () => carouselData,
  }));

  const deleteVideo = async (event) => {
    event.preventDefault();
    deleteEvent({
      userId: appState.userData.id,
      videoId: selectedItem,
    });
  };

  const settings = {
    dots: false,
    infinite: isCarouselInfinite,
    speed: 500,
    slidesToShow: Math.min(3, carouselData.length),
    slidesToScroll: 1,
    adaptiveHeight: true,
    variableWidth: true,
    className: "slide-ad-pitch",
    initialSlide: initialSlide,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: carouselData.length > 3,
          dots: true,
          variableWidth: false, // Ensure it fits within the 1500px screen width
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: carouselData.length > 3,
          dots: true,
          variableWidth: false, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: carouselData.length > 2,
          dots: true,
          // variableWidth: false, 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: carouselData.length > 1,
        },
      },
    ],
  };

  const handleCardSelect = (item, index) => {
    updateStateIndex();
    setSelectedCardIndex(index);
    onSelectCard(item);
  };

  const getCardSelected = (item) => {
    return carouselData[currentStateIndex]._id === item._id;
  };

  return (
    <>
      <DeleteVideoConfirmation isOpen={isDialogOpen} onClose={onClose} onDelete={deleteVideo} />
      <CarouselContainer>
        <Slider ref={sliderRef} {...settings}>
          {carouselData.map((item, index) => (
            <Slide key={index} onClick={() => handleCardSelect(item, index)}>
              <CarouselLaunchCard
                {...item}
                editAction={onEditActionClicked}
                isDialogOpen={isDialogOpen}
                onOpen={onOpen}
                onClose={onClose}
                setItemId={setSelectedItem}
                isSelected={
                  currentStateIndex === null
                    ? carouselData[selectedCardIndex]._id === item._id
                    : getCardSelected(item, index)
                }
              />
            </Slide>
          ))}
        </Slider>
      </CarouselContainer>
    </>
  );
});

export default Carousel;