import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ProgressDot } from "../../../components/forms/onboarding/progressDot";
import Button from "../../../components/UIcomponents/Button";
import Link from "../../../components/UIcomponents/Link";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { AppContext } from "../../../context/AppContext";
export const entityTypes = [
  { value: 'llc', label: 'LLC' },
  { value: 'llp', label: 'LLP' },
  { value: 'corporation', label: 'Corporation' }
]
export const BusinessCompanyType = () => {
  const progressSteps = [false, true, false, false, false];
  const [legalEntityType, selectEntity] = useState('')
  const [registerApiValidatorResponse, setRegisterApiValidator] = useState({});
  const { appState, updateOnboardingData } = useContext(AppContext);
  const { userData } = appState;
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.legalEntityType) {
      const defaultOption = entityTypes.find(option => option.label === userData.legalEntityType);
      selectEntity(defaultOption);
    }
  }, [userData]);

  const moveToNext = async () => {
    await updateOnboardingData({
      userData: { ...userData, legalEntityType: legalEntityType.label }
    })
    await navigate('/business-registration/ein')
  }
  
  const handleChange = (e) => {
    selectEntity(e)
  }

  const moveToRegister = async () => {
    await navigate("/register");
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
  }


  return (
    <RegistrationContainer>
      <Header>
        <HeaderTitle>Business Registration</HeaderTitle>
        <ProgressBar>
          {progressSteps.map((isActive, index) => (
            <ProgressDot key={index} isActive={isActive} />
          ))}
        </ProgressBar>
        <ExitButton onClick={moveToRegister}>Exit</ExitButton>
      </Header>
      <Main>
        <BusinessName>{userData.name}</BusinessName>
        <SectionTitle>Select your legal entity type:</SectionTitle>
     
            <Select
                  itemName="legalEntityType"
                  className="basic-single"
                  validation={registerApiValidatorResponse}
                  classNamePrefix="select"
                  placeholder='Choose an option below'
                  isDisabled={false}
                  value={legalEntityType}
                  isSearchable={false} 
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      textAlign: 'left',
                      backgroundColor: '#FF746E',
                      color: '#848484',
                      borderColor: state.isFocused ? '#FFB2A7' : baseStyles.borderColor, // Change border color on focus
                      boxShadow: state.isFocused ? '0 0 0 1px #FFB2A7' : baseStyles.boxShadow, // Optional: Add box-shadow on focus
                      border: registerApiValidatorResponse.hasOwnProperty('legalEntityType') ? 'solid 1px #FF746E' :  'solid 1px #FF746E',
                      '&:hover': {
                        borderColor: '#FFB2A7' 
                      }
                    }),
                    option: (base, state) => ({
                      ...base,
                      textAlign: 'left',
                      color: '#848484',
                      backgroundColor: state.isSelected ? '#FFFBF7' : state.isFocused ? '#FFFBF7' : 'white',
                      borderBottom: '1px solid #E4E4E4',
                      width: '100%'
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#848484' 
                    })
                  }}
                  name="color"
                  onChange={(e) => {delete registerApiValidatorResponse['legalEntityType']; handleChange(e)}}
                  options={entityTypes}
                />
        <NavigationButtons>
        <Button isDisabled={!legalEntityType} onClick={ (e) => moveToNext(e)} type="button">
          Next
       </Button>
       <Link href="/business-registration/revenue">Back</Link>
        </NavigationButtons>
      </Main>
    </RegistrationContainer>
  );
};

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
`;

const RegistrationContainer = styled.div`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 970px;
  flex-direction: column;
  align-items: center;
  padding: 54px 41px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const HeaderTitle = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BusinessName = styled.h2`
  color: #ffb9b6;
  text-align: center;
  letter-spacing: -0.48px;
  margin-top: 88px;
  font: 500 16px BR Omny, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h3`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 17px;
  font: 400 24px BR Omny, sans-serif;
`;

const NavigationButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const NextButton = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 100%
  ),
  #ff746e;
  width: 400px;
  max-width: 100%;
  color: #fff;
  white-space: nowrap;
  padding: 16px 40px;
  font: 500 16px/120% BR Omny, sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: normal;
    padding: 16px 20px;
  }
`;

const BackButton = styled.button`
  color: #ff746e;
  margin-top: 16px;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;