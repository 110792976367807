import React from "react";
import styled from "styled-components";
import Button from "../components/UIcomponents/Button";
import Link from "../components/UIcomponents/Link";
import { useNavigate } from "react-router-dom";

const AccountRegistrationPage = () => {
  const navigate = useNavigate();
  const moveToNext = () => {
    navigate("/account-confirmation/confirm")
  }
  

  return (
    <FormContainer>
      
      <FormContent>
        <FormTitle>Account Confirmation</FormTitle>
        <FormDescription>Let’s confirm your account info.</FormDescription>
        <EstimatedTime>Est. Time: 2 mins</EstimatedTime>
        
        <Button onClick={ (e) => moveToNext(e)} type="button">
          Begin
        </Button>

        <Link href="/register">Start Over</Link>
      </FormContent>
    </FormContainer>
  );
};

const FormContainer = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 973px;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FormContent = styled.section`
  display: flex;
  width: 505px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FormTitle = styled.h1`
  color: #000;
  text-align: center;
  align-self: stretch;
  font: 600 40px BR Omny, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormDescription = styled.p`
  color: #111;
  text-align: center;
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.54px;
  margin-top: 8px;
`;

const EstimatedTime = styled.p`
  color: #848484;
  text-align: center;
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.54px;
  margin-top: 30px;
      margin-bottom: 30px;
`;

export default AccountRegistrationPage;