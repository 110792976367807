import React from "react";
import styled, { keyframes } from "styled-components";
import Success from './check-circle.svg';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const SuccessNotification = ({ isVisible, successText }) => {
  return (
    <NotificationContainer isVisible={isVisible}>
      <NotificationContent>
        <NotificationWrapper>
          <SuccessIcon loading="lazy" src={Success} alt="Success icon" />
          <SuccessMessage>
            {successText.includes('Success') ? (
              <>
                {successText} <strong>LIVE</strong>!
              </>
            ) : (
              successText
            )}
          </SuccessMessage>
        </NotificationWrapper>
      </NotificationContent>
    </NotificationContainer>
  );
};


const NotificationContainer = styled.section`
  animation: ${({ isVisible }) => isVisible ? fadeIn : fadeOut} 2s ease-in-out;
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};
  border-radius: 30px;
  position: absolute;
  z-index: 1;
  top: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fdfffa;
  box-shadow: 0 2px 15px rgba(158, 158, 158, 0.25);
  display: flex;
  max-width: 365px;
  color: #000;
  letter-spacing: -0.54px;
  padding: 24px 32px;
  font: 400 18px BR Omny, sans-serif;
`;

const NotificationContent = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
`;

const NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

const SuccessIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

const SuccessMessage = styled.p`
  margin: 0;
`;

export default SuccessNotification;