import { Card,  SparkAreaChart } from "@tremor/react";
import '../dashboard/dashboard.css'

export function DataGraph({ keyName, kpiData }) {
  return (
    <Card className="data-graph-card max-w-lg flex items-center justify-between px-0 py-3.5">
      <div className="flex items-center space-x-2.5">
      </div>
      <SparkAreaChart
        curveType="monotone"
        data={kpiData.analytics}
        showAnimation="true"
        animationDuration="1000"
        categories={[keyName]}
        index={"date"}
        colors={["rose"]} /* Set the color to #FF746E */
        className="custom-spark-area-chart h-10 w-56"
      />
      <div style={{ marginLeft: "15px" }} className="flex items-center space-x-2.5">
      </div>
    </Card>
  );
}