import React  from "react";
import styled from "styled-components";
import { Dialog } from "@tremor/react";

const DeleteVideoConfirmation = ({ isOpen, onClose, onDelete }) => {

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} static={true}>
        <DialogContent>
          <ConfirmationCard>
            <CardTitle>Delete your video?</CardTitle>
            <CardMessage>
              Are you sure you want to delete your video ad pitch? Your post will be deleted.
            </CardMessage>
            <ButtonContainer>
              <ActionButton variant="secondary" onClick={onClose}>
                Cancel
              </ActionButton>

              <ActionButton variant="primary" onClick={onDelete}>
                Delete Video Ad Pitch
              </ActionButton>
            </ButtonContainer>
          </ConfirmationCard>
        </DialogContent>
      </Dialog>
    </>
  );
};

const OpenDialogButton = styled.button`
  padding: 10px 20px;
  background-color: #f93931;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const DialogContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
`;

const ConfirmationCard = styled.section`
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  display: flex;
  max-width: 461px;
  flex-direction: column;
  align-items: flex-start;
  padding: 52px 40px;
  font: 16px BR Omny, sans-serif;
  letter-spacing: -0.48px;

  @media (max-width: 768px) {
    padding: 30px 20px;
    max-width: 90%;
  }
`;

const CardTitle = styled.h2`
  color: #000;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.84px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const CardMessage = styled.p`
  color: #000;
  font-weight: 400;
  align-self: stretch;
  margin-top: 16px;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 53px;
  width: 100%;
  max-width: 316px;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ActionButton = styled.button`
  align-self: stretch;
  border-radius: 10px;
  gap: 8px;
  padding: 16px 18px;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;

  ${({ variant }) =>
    variant === "primary"
      ? `
    background-color: #f93931;
    color: #fff;
    &:hover {
      background-color: #e62d25;
    }
  `
      : `
    background-color: transparent;
    color: #ff746e;
  `}

  @media (max-width: 768px) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;

export default DeleteVideoConfirmation;