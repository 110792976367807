import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ProgressDot } from "../../../components/forms/onboarding/progressDot";
import InputCurrency from "../../../components/UIcomponents/currencyInput";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/UIcomponents/Button";
import Link from "../../../components/UIcomponents/Link";
import { AppContext } from "../../../context/AppContext";

const BusinessRegistration = () => {
  const progressSteps = [true, false, false, false, false];
  const { appState, updateOnboardingData } = useContext(AppContext);
  const { userData, loading, error } = appState;
  const navigate = useNavigate();
  
  const [revenue, setRevenue] = useState('');

  useEffect(() => {
    if (userData && userData.revenue) {
      setRevenue(userData.revenue);
    }
  }, [userData]);

  const formatAmount = (e) => {
    setRevenue(e);
  };

  const onKeyUp = () => { 
    console.log('key up');
  };

  const moveToNext = async () => {
    await updateOnboardingData({
      userData: { ...userData, revenue }
    });

    await navigate('/business-registration/select-entity');
  };
  
  const moveToRegister = async () => {
    await navigate("/register");
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
  };

  return (
    <RegistrationContainer>
      <Header>
        <Title>Business Registration</Title>
        <ProgressBar>
          {progressSteps.map((isActive, index) => (
            <ProgressDot key={index} isActive={isActive} />
          ))}
        </ProgressBar>
        <ExitButton onClick={moveToRegister}>Exit</ExitButton>
      </Header>
      <BusinessName>
        {loading && <div>Loading...</div>}
        {!loading && error && <div>Error: {error}</div>}
        {!loading && !error && !userData && <div>No user data available.</div>}
        {!loading && !error && userData && userData.name && (
          <span>{userData.name}</span>
        )}
      </BusinessName>
      <Question>What is your current business revenue? (in USD)</Question>
      <InputCurrency trackKeyUp={onKeyUp} onGetNumber={formatAmount} value={revenue} />
      <Button isDisabled={!revenue} onClick={moveToNext} type="button">
        Next
      </Button>
      <Link href="/business-registration">Back</Link>
    </RegistrationContainer>
  );
};

const RegistrationContainer = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 970px;
  flex-direction: column;
  align-items: center;
  padding: 44px 41px 80px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Title = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const BusinessName = styled.h2`
  color: #ffb9b6;
  text-align: center;
  letter-spacing: -0.48px;
  margin-top: 88px;
  font: 500 16px BR Omny, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Question = styled.p`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 17px;
  width: 412px;
  font: 400 24px BR Omny, sans-serif;
`;

export default BusinessRegistration;