import React, { useState } from "react";
import styled from "styled-components";
import { AreaChart } from "@tremor/react";


const Chart = ({ performance, selectedCount }) => {


  if(performance && performance.length) {
  performance.map((item) => {
    item.date = item.date.split('T')[0]
    return item
  })  
  }  

  return (
    <ChartWrapper>
      <AreaChart
        className={`"mt-8 h-96`}
        data={performance}
        index="date"
        categories={[selectedCount]}
        colors={["rose"]}
        animationDuration={3000}
        showLegend={false}
        showAnimation={true}
        showGridLines={true}
        curveType="monotone"
      />
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  width: 78%;
  margin-left: 20px;
  @media (max-width: 1199px) {
    width: 100%;
    margin-left: -25px;
  }
`;

export default Chart;