import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress }  from 'react-places-autocomplete';
import styled from 'styled-components';


const AutocompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  color: #111;

text-align: center;
font-family: "BR Omny";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.48px;
`;

const GooglePlaceOption = styled.div`
text-align: left;
border-bottom: 1px solid #D9D9D9;
cursor: pointer;
background: #fff;
padding-top: 7px;
padding-bottom: 7px;
`;

const GoogleAutocompleteInput = styled.input`
border:none;
border-bottom: solid 1px #D9D9D9;
padding: 3px 15px 2px 0px;


width: 100%;
margin: 8px 0;

&:focus {
border-bottom: solid 1px #D9D9D9;
outline: none;
box-shadow: none;
background: #F6F6F6;
}

${props => props.validation && props.itemName && props.validation[props.itemName] && `
transition: .2s; 


  
`}
`;

const GoogleAutocomplete = ({
    onSelect
}) => {
  const [address, setAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

 
  const handleSelect = (selected, placeId, suggestion) => {
    setAddress(selected);
    geocodeByAddress(selected)
      .then((res) => res[0])
      .then((data) => {
          onSelect(getFullAddress(data, suggestion.formattedSuggestion.mainText), suggestion.description);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getFullAddress = async (autoComplete, name) => {
    console.log(autoComplete)
    const place = autoComplete;
    let address1, 
        locality, 
        adminArea1Short, 
        postalCode = "";

    // Get each component of the address from the place details,
    for (const component of place.address_components) {
    
        const componentType = component.types[0];

        if (componentType === "street_number") {
            address1 = component.long_name;
        }
        if (componentType === "route") {
            address1 = address1 ? `${address1} ${component.long_name}` : `${component.long_name}`
        }
        if (componentType === "locality") {
            locality = component.long_name;
        }
        if (componentType === "administrative_area_level_1") {
            adminArea1Short = component.short_name;
        }
        if (componentType === "postal_code") {
            postalCode = component.long_name;
        }
        if (componentType === "postal_code_suffix") {
            postalCode = `${postalCode}-${component.long_name}`;
        }

    }
    let resAddress = {
        "city": locality,
        "zipCode": postalCode,
        "street": address1,
        "state": adminArea1Short,
        "name": name
    };
    return resAddress;
};

  const handleError = (status, clearSuggestions) => {
    status = 'No Data!'
    setErrorMessage(status);
    clearSuggestions();
  };

  const handleChange = value => {
    setAddress(value);
    setErrorMessage('');
  };

  return (
    <AutocompleteContainer>
     {window.google &&  <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <>
            <GoogleAutocompleteInput
              {...getInputProps({
                placeholder: 'Type in your address',
              })}
            />
              <div style={{width: '100%', position: 'absolute', top: '45px', zIndex: '999'}}>
                {suggestions.map((suggestion, index) => (
                  <GooglePlaceOption
                    key={index}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </GooglePlaceOption>
                ))}
              </div>
          </>
        )}
      </PlacesAutocomplete> }

      {errorMessage.length > 0 && (
        <div>{errorMessage}</div>
      )}
    </AutocompleteContainer>
  );
};



export default GoogleAutocomplete;