import React from "react";
import styled, { css } from "styled-components";

const Button = ({ children, size, isDisabled, onClick }) => (
  <StyledButton size={size} disabled={isDisabled} onClick={onClick}>
    {children}
  </StyledButton>
);

const sizeStyles = {
  xsmall: css`
  height: 51px;
  padding: 16px 24px 16px 24px;
  width: 153px;

  `,
  small: css`
    font-size: 12px;
    padding: 8px 16px;
  `,
  medium: css`
    font-size: 16px;
    padding: 12px 24px;
  `,
  large: css`
    font-size: 20px;
    padding: 16px 32px;
  `,
};

const StyledButton = styled.button`
  font-family: BR Omny, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FF746E;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  display: flex;
  width: 400px;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  ${(props) => sizeStyles[props.size || "medium"]}
  
  @media (max-width: 991px) {
    padding: ${(props) => (props.size === "small" ? "8px 10px" : props.size === "large" ? "16px 20px" : "16px 20px")};
  }
`;

export default Button;