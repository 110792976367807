import React from "react";
import styled from "styled-components";

const InputField = ({ placeholder, type, value, onChange, error, name }) => (
  <InputWrapper>
    <StyledInput
      name={name}
      type={type}
      placeholder={placeholder}
      aria-label={placeholder}
      value={value}
      onChange={onChange}
      hasError={!!error}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </InputWrapper>
);

const InputWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const StyledInput = styled.input`
  font-family: BR Omny, sans-serif;
  color: #111;
  border-radius: 10px;
   &:focus {
    border-color: rgba(255, 116, 110, 1);
    background-color: #fcfcfc;
        box-shadow: none;
  }
  border: 1px solid ${(props) => (props.hasError ? "#ff0000" : "rgba(228, 228, 228, 1)")};
  background-color: #fcfcfc;
  width: 100%;
  padding: 16px;
  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 14px;
  margin-top: 4px;
`;

export default InputField;