import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const EINInput = ({ defaultEin = '', onEinChange, onInputEin }) => {
  // Remove any non-digit characters from defaultEin and convert it to an array of characters
  const defaultEinValues = defaultEin.replace(/[^0-9]/g, '').split('');
  // Ensure the array has exactly 9 elements, filling with empty strings if necessary
  const initialEinValues = [...defaultEinValues, ...Array(9 - defaultEinValues.length).fill('')];
  
  const [einValues, setEinValues] = useState(initialEinValues);
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 9);
  }, []);

  useEffect(() => {
    const einString = einValues.slice(0, 2).join('') + '-' + einValues.slice(2).join('');
    if (!einValues.includes('')) {
      onEinChange(einString);
    }
    onInputEin(einString);
  }, [einValues, onEinChange, onInputEin]);

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newEinValues = [...einValues];
      newEinValues[index] = value;
      setEinValues(newEinValues);

      if (value && index < 8) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !einValues[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <InputContainer>
      {einValues.map((value, index) => (
        <React.Fragment key={index}>
          {index === 2 && <Separator>-</Separator>}
          <InputGroup>
            <VisibleInput
              ref={(el) => (inputRefs.current[index] = el)}
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              maxLength={1}
            />
          </InputGroup>
        </React.Fragment>
      ))}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  margin-top: 38px;
  gap: 10px;
  font-size: 32px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const InputGroup = styled.div`
  display: flex;
  padding-top: 11px;
  flex-direction: column;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const VisibleInput = styled.input`
    font-family: BR Omny, sans-serif;
    font-size: 32px;
    color: #111;
    text-align: center;
    width: 34px;
    border: none;
    border-bottom: 2px solid #111;
    outline: none;
    background: transparent;
    padding: 5px;
  
  &:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid #D9D9D9;
  }
`;

const Separator = styled.div`
  font-family: BR Omny, sans-serif;
  color: #111;
  justify-content: center;
  padding: 20px 2px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default EINInput;
