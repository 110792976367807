import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import { getUserData, refreshToken } from '../services/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from "react-cookie";

const accountTypes = [
  { value: 0, label: 'Guest' },
  { value: 1, label: 'CyaSponsor' },
  { value: 2, label: 'CyaSpot' }
];

// Create the context
export const AppContext = createContext();

// Function to get user data
const getDataForContext = async (onboardingId, setAppState) => {
  try {
    const userData = await getUserData(onboardingId);
    userData.accountTypeLabel = accountTypes.find(item => item.value === userData.accountType)?.label;
    setAppState(prevState => {
      const newState = { ...prevState, userData, loading: false, error: null };
      localStorage.setItem('appState', JSON.stringify(newState));

      // Update onboardingData
      const onboardingData = JSON.parse(localStorage.getItem('onboardingData')) || {};
      const updatedOnboardingData = { ...onboardingData, userData };
      localStorage.setItem('onboardingData', JSON.stringify(updatedOnboardingData));

      return newState;
    });
  } catch (error) {
    console.error('Error fetching user data:', error);
    setAppState(prevState => {
      const newState = { ...prevState, userData: null, loading: false, error: error.message };
      localStorage.setItem('appState', JSON.stringify(newState));
      return newState;
    });
  }
};

// Create the provider component
export const AppProvider = ({ children }) => {

  const [appState, setAppState] = useState(() => {
    try {
      const savedState = localStorage.getItem('appState');
      return savedState 
        ? JSON.parse(savedState) 
        : { userData: null, loading: true, error: null };
    } catch (error) {
      console.error('Error parsing appState from localStorage:', error);
      return { userData: null, loading: true, error: null };
    }
  });

  const hasFetchedData = useRef(false);

  const setDataForContext = useCallback(() => {
    if (hasFetchedData.current) {
      return;
    }
    hasFetchedData.current = true;

    try {
      const onboardingData = JSON.parse(localStorage.getItem('onboardingData'));
      if (onboardingData && onboardingData.id && !hasFetchedData.current) {
        hasFetchedData.current = true;
        getDataForContext(onboardingData.id, setAppState);
      } else {
        setAppState(prevState => ({
          ...prevState,
          loading: false,
          error: onboardingData ? null : 'No onboarding data found'
        }));
      }
    } catch (error) {
      console.error('Error in setDataForContext:', error);
      setAppState(prevState => ({
        ...prevState,
        loading: false,
        error: 'Error fetching data'
      }));
    }
  }, []);

  const updateOnboardingData = useCallback((newData) => {
    try {
      const onboardingData = JSON.parse(localStorage.getItem('onboardingData')) || {};
      const updatedOnboardingData = { ...onboardingData, ...newData };
      localStorage.setItem('onboardingData', JSON.stringify(updatedOnboardingData));

      if (newData.userData) {
        setAppState(prevState => {
          const newState = { ...prevState, userData: newData.userData };
          localStorage.setItem('appState', JSON.stringify(newState));
          return newState;
        });
        return true; 
      }
      return false;
    } catch (error) {
      console.error('Error updating onboarding data:', error);
    }
  }, []);

  useEffect(() => {

    setDataForContext();
    const retryInterval = setInterval(() => {
      if (!appState.userData && !appState.error) {
        setDataForContext();
      } else {
        clearInterval(retryInterval);
      }
    }, 5000); // Retry every 5 seconds

    return () => clearInterval(retryInterval);
  }, [setDataForContext, appState.userData, appState.error]);

  return (
    <AppContext.Provider value={{ appState, setAppState, updateOnboardingData }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook for using the AppContext
export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};