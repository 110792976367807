import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { loginUser } from "../services/auth";
import { isJson } from "../utils/transformApiErrors";
import { AppContext } from "../context/AppContext";
import { toast } from "react-toastify";
import logo from '../images/logoBiz.png'
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);


  const { appState, setAppState, updateOnboardingData } = useContext(AppContext);
  const { userData, loading, error } = appState;
  const [registerApiValidatorResponse, setRegisterApiValidator] = useState({});
  const [inputErrors, setInputErrors] = useState({ email: false, password: false });
  const navigate = useNavigate();

  useEffect(() => {
    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    setIsButtonDisabled(!(isValidEmail && password.length >= 8));
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let userLogin = await loginUser({
      email,
      password: password,
      rememberMe
    });
  
    if (userLogin === null) {
      toast.error("Server Disconnected!", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      if (userLogin.user && userLogin.user.id) {
        await updateOnboardingData({
          userData: userLogin.user, loading: false
        });
        await navigate('/dashboard', { replace: true });
      }
  
      if (userLogin.code === 401) {
        toast.error(userLogin.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
  
      if (userLogin.code === 500 || userLogin.code === 400) {
        let validatorData;
  
        if (isJson(userLogin.message)) {
          let parsedData = JSON.parse(userLogin.message);
          setRegisterApiValidator(parsedData);
          validatorData = parsedData;
        } else {
          validatorData = {
            details: userLogin.message || "Server Error"
          };
        }
  
        // Set input errors
        setInputErrors({
          email: validatorData.email ? true : false,
          password: validatorData.password ? true : false,
        });
  
        Object.keys(validatorData).forEach((key) => {
          toast.error(validatorData[key], {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
      }
    }
  };
  

  return (
    <StyledLoginContainer>
      <LoginHeader />
      <LoginForm
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
        handleSubmit={handleSubmit}
        isButtonDisabled={isButtonDisabled}
        inputErrors={inputErrors}
      />
      <SignUpPrompt />
    </StyledLoginContainer>
  );
};

const LoginHeader = () => (
  <StyledLoginHeader>
    <StyledLogo src={logo} alt="Company Logo" />
    <StyledLoginTitle>Log In</StyledLoginTitle>
  </StyledLoginHeader>
);

const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  rememberMe,
  setRememberMe,
  handleSubmit,
  isButtonDisabled,
  inputErrors,
}) => {
  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <InputField
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        haserror={inputErrors.email.toString()}
      />
      <InputField
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        haserror={inputErrors.password.toString()}
      />
      <StyledFormOptions>
        <RememberMe checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
        <ForgotPassword />
      </StyledFormOptions>
      <SubmitButton type="submit" disabled={isButtonDisabled}>
        Log In
      </SubmitButton>
    </StyledLoginForm>
  );
};

const InputField = ({ type, placeholder, value, onChange, haserror }) => (
  <StyledInput
    type={type}
    placeholder={placeholder}
    aria-label={placeholder}
    value={value}
    onChange={onChange}
    data-has-error={haserror.toString()} // Ensure attribute is always a string
  />
);

const ForgotPassword = () => (
  <StyledForgotPassword to="/forgot-password">Forgot password?</StyledForgotPassword>
);

const SignUpPrompt = () => (
  <StyledSignUpPrompt>
    <p>New to Cyasoon Biz?</p>
    <StyledSignUpLink>
      <StyledSignUpText to="/register">Sign up now</StyledSignUpText>
    </StyledSignUpLink>
  </StyledSignUpPrompt>
);

const RememberMe = ({ checked, onChange }) => (
  <StyledRememberMe>
    <HiddenCheckbox type="checkbox" checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked}>
      {checked ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path 
            d="M7.5 12L10.5 15L16.5 9M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z" 
            stroke="#FF746E" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path 
            d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z" 
            stroke="black" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          />
        </svg>
      )}
    </StyledCheckbox>
    <span>Remember me</span>
  </StyledRememberMe>
);

const StyledRememberMe = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (!props.checked && "transparent")};
`;

const StyledLoginContainer = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  max-width: 571px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 120%;
  padding: 40px 80px 80px;
  @media (max-width: 991px) {
    padding: 40px 20px 80px;
  }
`;

const StyledLoginHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogo = styled.img`
  aspect-ratio: 3.57;
  object-fit: contain;
  width: 144px;
  max-width: 100%;
`;

const StyledLoginTitle = styled.h1`
  color: #000;
  text-align: center;
  margin-top: 40px;
  font: 600 48px BR Omny, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const StyledLoginForm = styled.form`
  width: 100%;
  margin-top: 42px;
`;

const StyledInput = styled.input.attrs(props => ({
  'data-has-error': props['data-has-error'], // Use custom data attribute
}))`
  font-family: BR Omny, sans-serif;
  border-radius: 10px;
  border: 1px solid rgba(228, 228, 228, 1);
  background-color: #fcfcfc;
  width: 100%;
  color: #848484;
  font-weight: 400;
  padding: 16px;
  margin-bottom: 16px;
  @media (max-width: 991px) {
    padding: 16px 20px 16px 16px;
  }
  ${(props) =>
    props['data-has-error'] === 'true' &&
    css`
      border-color: red;
    `}
`;


const StyledFormOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  gap: 20px;
  font-weight: 400;
  justify-content: space-between;
`;

const StyledForgotPassword = styled(RouterLink)`
  color: #ff746e;
  font-family: BR Omny, sans-serif;
  text-decoration: none;
`;

const SubmitButton = styled.button`
  font-family: BR Omny, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ff746e;
  margin-top: 32px;
  color: #fff;
  font-weight: 500;
  padding: 16px 40px;
  border: none;
  cursor: pointer;
  transition: 0.9s;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  @media (max-width: 991px) {
    padding: 16px 20px;
  }
`;

const StyledSignUpPrompt = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 7px;
  align-items: center;
`;

const StyledSignUpLink = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledSignUpText = styled(RouterLink)`
  color: #ff746e;
  font-family: BR Omny, sans-serif;
  font-weight: 600;
  text-decoration: none;
`;

const StyledSignUpIcon = styled.span`
  border: 3px solid rgba(255, 38, 233, 1);
  background-color: rgba(255, 38, 233, 0.3);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: inline-block;
`;

export default LoginPage;
