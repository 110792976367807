
export const registerUser = async (userData) => {
    try { 
        const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/register`

        const response = await fetch(url, {method: "POST", headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, body: JSON.stringify(userData), credentials: "include" });
        const data = await response.json();
       
        return data;
      } catch (error) {
        console.log('Error post data:', error);
        return null;
      }
}

export const businessRegistration = async (userBusinessData, userId, token) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/register/business?userId=${userId}`

    const response = await fetch(url, {method: "PUT", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` 
    }, body: JSON.stringify(userBusinessData), credentials: "include"  });
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}

export const addressRegistration = async (addressData, userId, token) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/register/address?userId=${userId}`

    const response = await fetch(url, {method: "PUT", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` 
    }, body: JSON.stringify(addressData), credentials: "include"  });
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}

export const updateAnalytics = async (userId, token, locationId,accountType) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/update/business-analytics?userId=${userId}&locationId=${locationId}&accountType=${accountType}`

    const response = await fetch(url, {method: "PUT", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }  });
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}

export const getUserData = async (userId, token) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/userdata?userId=${userId}`

    const response = await fetch(url, {method: "GET", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` 
    }, });
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}


export const loginUser = async (userData) => {
  try { 
      const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/login`

      const response = await fetch(url, {method: "POST", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, body: JSON.stringify(userData), credentials: "include" });

      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}

export const refreshToken = async (tokenId) => {
  try { 
      const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/refresh-tokens`

      const response = await fetch(url, {method: "POST", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }, credentials: "include" });

      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}


export const checkAuthStatus = async () => {
  try {
    const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/status`

    const response = await fetch(url, {
      credentials: 'include', 
    });
    const result = await response.json();
    return result.authenticated;
  } catch (error) {
    console.error('Failed to check auth status:', error);
    return false;
  }
};

export const logOutUser = async () => {
  try { 
      const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/logout`

      const response = await fetch(url, {method: "POST", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, credentials: "include" });

      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}

export const updateProfile = async (userData, id) => {
  try{
    const url = `${process.env.REACT_APP_CYASOON_API}v1/users/update-profile/${id}`
    
    const response = await fetch(url, {method: "PUT", headers: {
      'Accept': "application/json",
      'Content-Type': 'application/json'

    }, body: JSON.stringify(userData) });
    const data = await response.json();

    return {data, status: response.status};
  }catch(error){
    console.log("Error updating profile: ", error);
    return null;
  }
}

export const forgotPassword = async (userData) => {
  try { 
      const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/forgot-password`

      const response = await fetch(url, {method: "POST", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, body: JSON.stringify(userData) });
      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}


export const resetPassword = async (userData) => {
  try { 
      
      const url = `${process.env.REACT_APP_CYASOON_API}v1/auth/reset-password?token=${userData.token}`

      const response = await fetch(url, {method: "POST", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, body: JSON.stringify({
        password: userData.password
      }) });
      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}

export const businessIdentify = async (token, locationData) => {
  try { 
    const url = new URL(`${process.env.REACT_APP_CYASOON_API}v1/auth/business-identify`)
    const params = new URLSearchParams(locationData)
    url.search = params.toString()  

      const response = await fetch(url, {method: "GET", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` 
      }});
      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}