import styled, {keyframes, css} from "styled-components";

const breatheAnimation = keyframes`
 0% { opacity: 0; height: 0;  }
 100% { opacity: 1; height: 100%;  }
`
export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 678px;
  max-width: 100%;
  min-height: 400px;

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 767px)
  and (-webkit-min-device-pixel-ratio: 2) {
      width: 350px;
  }
`;

export const ResetPasswordContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-top: 25px;
` 

export const SignUpContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  ${props =>
    props.signingIn !== true
      ? `
  transform: translateX(100%);
	opacity: 1;
	z-index: 5;
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 767px)
  and (-webkit-min-device-pixel-ratio: 2) {
      transform: none;
      width: 100%;
  }
	`
      : null}
`;

export const BusinesDetailSignUpContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 25px 0px 25px 0px;
`;

export const AddressDetailsRegistratonContainer = styled.div`
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 90px 25px 90px;
`;

export const SignInContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  z-index: 2;
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 767px)
  and (-webkit-min-device-pixel-ratio: 2) {
      width: unset;
      transform: none;
  }
  ${props => (props.signingIn !== true ? `transform: translateX(100%); ` : null)}
`;

export const Form = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: ${breatheAnimation};
  animation-duration: 1s;
  animation-iteration-count: ease-in;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
`;

export const subTitle = styled.h1`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
`;

export const Input = styled.input`
color: #111;
    text-align: center;
    font-family: "BR Omny";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    border-top: none;
    border-left: none;
    border-right: none;
    line-height: normal;
  border-bottom: 1px solid #D9D9D9;
    
  &:focus {
    box-shadow: none ;
    outline: none;
    border-bottom: 1px solid #D9D9D9;
  }
  padding: 12px 15px;
  margin: 32px 0px 24px 0px;
  width: 420px;
  ${props => props.validation && props.itemName && props.validation[props.itemName] && `
  border: solid 3px #FC5185;
  transition: .2s; `}
`;

export const Select = styled.select`
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 400px;
  ${props => props.validation && props.itemName && props.validation[props.itemName] && `
  border: solid 3px #FC5185;
  transition: .2s; `}
`;


export const MailingAddressWrapper = styled.div`
display: flex;
align-items: center;
flex-direction: column;
margin-top: 15px;
`;

export const PhysicalAddressWrapper = styled.div`
display: flex;
align-items: center;
flex-direction: column;
margin-bottom: 10px
`;

export const subAddressInformation =  styled.div`
  display: flex;
  input:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Button = styled.button`
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  cursor: pointer;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 1;
  transition: transform 80ms ease-in;
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
  ${props =>
    props.disabled  && `opacity: 0.3`}
`;

export const GhostButton = styled(Button)`
  background-color: transparent;
  border-color: #ffffff;
`;

const baseAnchorStyles = css`
color: #333;
font-size: 14px;
text-decoration: none;
margin: 15px 0;
cursor: pointer;
`;

export const Anchor = styled.a`
    ${baseAnchorStyles}
`;

export const AnchorStyled = styled.a`
  color: #ff4b2b;
  font-weight: bold;
  ${baseAnchorStyles}
`;

export const AnchorUnderline = styled.a`
    ${baseAnchorStyles}
    color: #ff4b2b;
    font-weight: bold;
    border-bottom: 2px solid;
    margin-top: 10px;
    display: flex;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 767px)
  and (-webkit-min-device-pixel-ratio: 2) {
      width: unset;
      transform: none;
  }
  ${props =>
    props.signingIn !== true ? `transform: translateX(-100%);` : null}
`;

export const Overlay = styled.div`
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  ${props => (props.signingIn !== true ? `transform: translateX(50%);` : null)}
`;

export const OverlayPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
`;

export const LeftOverlayPanel = styled(OverlayPanel)`
  left: 0;
  transform: translateX(-20%);
  ${props => (props.signingIn !== true ? `transform: translateX(0);` : null)}
`;

export const RightOverlayPanel = styled(OverlayPanel)`
  right: 0;
  transform: translateX(0);
  ${props => (props.signingIn !== true ? `transform: translateX(20%);` : null)}
`;
export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 10px;
`;
