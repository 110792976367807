import React, { useState, useRef, useEffect, useContext} from 'react';
import styled from 'styled-components';
import { DatePicker } from '@tremor/react';
import { Switch } from '@tremor/react'; 
import Upload from '../../images/upload-01.svg';
import trash from '../../images/trash-01.svg';
import Button from '../UIcomponents/Button';
import VideoPlayer from '../UIcomponents/VideoPlayer';
import DeleteVideoConfirmation from "./DeleteVideoPitch"; 

const AdCreationForm = ({ publishEvent, deleteEvent, initialData = null, isDialogOpen, onOpen, onClose }) => {
  const [videoPreview, setVideoPreview] = useState(null);
  const [title, setTitle] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [description, setDescription] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [dataHasChanged, setData] = useState(false);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  const fileInputRef = useRef(null);
  const detailsContainerRef = useRef(null);

  useEffect(() => {
    if (initialData) {
      const { title, description, expireDate, isActive, videoAdUrl } = initialData;
      setTitle(title || '');
      setDescription(description || '');
      setExpiryDate(expireDate ? new Date(expireDate) : null);
      setIsActive(isActive || false);
      if (videoAdUrl) {
        setVideoPreview(videoAdUrl);
      }
    }
  }, [initialData]);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    const maxFileSize = 50 * 1024 * 1024;
    const maxDuration = 16;

    if (file) {
      if (file.size > maxFileSize) {
        alert("The file is too large. Please upload a file smaller than 50MB.");
        event.target.value = "";
        return;
      }

      const videoUrl = URL.createObjectURL(file);

      const video = document.createElement('video');
      video.src = videoUrl;

      video.onloadedmetadata = () => {
        if (video.duration >= maxDuration) {
          alert("The video is too long. Please upload a video that is shorter than 16 seconds.");
          URL.revokeObjectURL(videoUrl);
          event.target.value = "";
        } else {
          setVideoPreview(videoUrl);
          setVideoFile(file);
          handleChangeData();
        }
        video.remove();
      };

      video.onerror = () => {
        alert("An error occurred while processing the video. Please try again.");
        URL.revokeObjectURL(videoUrl);
        event.target.value = "";
        video.remove();
      };
    }
  };

  const handlePublish = async (event) => {
    event.preventDefault();
    publishEvent({
      title,
      description,
      videoFile,
      isActive,
      isPublished: true,
      date: new Date(expiryDate).toISOString(),
      ...(initialData?._id && { id: initialData._id })
    });
  };

  const deletePitch = async (event) => {
    event.preventDefault();
    onOpen()
  };

  const deleteVideo = async (event) => {
    event.preventDefault();
    deleteEvent({
      userId: initialData.userId,
      videoId: initialData._id
    });
  };

  const handleChangeData = () => {
    setData(true);
  };

  const handleDateChange = (newDate) => {
    setExpiryDate(newDate);
  };

  const handleDatePickerClose = () => {
    setIsScrollEnabled(false);
  };

  const handleDatePickerOpen = () => {
    setIsScrollEnabled(true);

    setTimeout(() => {
      if (detailsContainerRef.current) {
        detailsContainerRef.current.scrollTop = detailsContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (detailsContainerRef.current && !detailsContainerRef.current.contains(event.target)) {
        setIsScrollEnabled(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const isButtonDisabled = initialData
    ? !dataHasChanged 
    : !title || !description || !videoFile || !expiryDate; 

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <DeleteVideoConfirmation 
        isOpen={isDialogOpen} 
        onClose={onClose} 
        onDelete={deleteVideo}
      />
      <FormContainer onSubmit={handlePublish}>
        <FormContent>
          <VideoUploaderContainer>
            {videoPreview ? (
              <VideoPlayer autoPlay={true} url={videoPreview} />
            ) : (
              <UploaderContent onClick={() => fileInputRef.current.click()}>
                <UploadIcon src={Upload} alt="Upload icon" />
                <UploadText>Upload your video</UploadText>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleVideoUpload}
                  accept="video/*"
                  style={{ display: 'none' }}
                />
              </UploaderContent>
            )}
          </VideoUploaderContainer>
          <DetailsContainer ref={detailsContainerRef} isScrollEnabled={isScrollEnabled}>
            <DetailsContent>
              <TitleContainer> 
                <Label htmlFor="adTitle">Title:</Label>
                <Title
                  id="adTitle"
                  value={title}
                  maxLength={50}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    handleChangeData();
                  }}
                  placeholder="Add a title"
                />
              </TitleContainer>
              <DescriptionContainer>
                <Label htmlFor="adDescription">Description:</Label>
                <Description
                  id="adDescription"
                  value={description}
                  maxLength={200}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    handleChangeData();
                  }}
                  placeholder="What is your ad about?"
                />
              </DescriptionContainer>
              <ControlsContainer>
                <DatePickerContainer>
                  <Label htmlFor="expiryDate">Expires:</Label>
                  <DatePickerWrapper>
                  <StyledDatePicker
  id="expiryDate"
  className="mx-auto max-w-sm"
  value={expiryDate || today} 
  minDate={today}
  onValueChange={handleDateChange}
  placeholder="Select date"
  onFocus={handleDatePickerOpen}
  onBlur={handleDatePickerClose}
/>
                  </DatePickerWrapper>
                </DatePickerContainer>
                <SwitchWrapper>
                  <Label htmlFor="activeSwitch">Active:</Label>
                  <Switch
                    id="activeSwitch"
                    checked={isActive}
                    onChange={() => {
                      setIsActive(prevState => !prevState);
                      handleChangeData();
                    }}
                  />
                </SwitchWrapper>
              </ControlsContainer>
            </DetailsContent>
            <EditBottom>
              {initialData ? (
                <ButtonDelete onClick={deletePitch}>
                  <img src={trash} alt="Delete icon" />
                </ButtonDelete>
              ) : null}
              <Button size="xsmall" isDisabled={isButtonDisabled}>
                {initialData ? 'Save Changes' : 'Publish Ad'}
              </Button>
            </EditBottom>
          </DetailsContainer>
        </FormContent>
      </FormContainer>
    </>
  );
};


const EditBottom = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const ButtonDelete = styled.button` 
  border-radius: 5px;
background: #FFF1F0;
width:40px;
height:40px;
display: flex;
justify-content: center;
align-items: center;
`

const StyledDatePicker = styled(DatePicker)`
  --tremor-date-picker-selected-background: #FF746E;
  --tremor-date-picker-selected-border-radius: 4px;
  z-index: 9999;
  &:focus, &:hover, &:blur {
    border: 1px solid #FF746E;
  }
  
  .tremor-DatePicker-root {
    position: relative;
  }

  .tremor-DatePicker-calendarContainer {
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FormContainer = styled.form`
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    // padding-right: 20px;
  }
`;

const FormContent = styled.div`
  display: flex;
  gap: 20px;
  height: 638px;
  @media (max-width: 991px) {
    // flex-direction: column;
    // align-items: stretch;
    // gap: 0;
  }

  @media screen and (max-width: 1500px) {
    height: 530px;
  }
  
  @media screen and (max-width: 768px) {
    height: 490px;
  } 
`;

const VideoUploaderContainer = styled.section`
  border-radius: 40px 0 0 40px;
  background-color: #fff4f4;
  min-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff746e;
  font: 500 16px 'BR Omny', sans-serif;
  letter-spacing: -0.48px;

  video {
    width: 459px;
    height: auto;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    // margin-top: 40px;
    // padding: 100px 20px;
  }

  @media screen and (max-width: 1500px) {
    min-width: auto;
    width: 360px;
  } 

  @media screen and (max-width: 768px) {
  width: 100%;
  }
`;

const UploaderContent = styled.div`
  display: flex;
  width: 130px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const UploadIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: center;
`;

const UploadText = styled.p`
  margin-top: 8px;
  text-align: center;
`;

const DetailsContainer = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 620px;
  padding-top: 48px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 40px;
  align-items: self-end;
  overflow-y: ${({ isScrollEnabled }) => (isScrollEnabled ? 'auto' : 'hidden')};
  max-height: 100%; 
  @media screen and (max-width: 1500px) {
    min-width: auto;
    padding: 40px;
  } 

  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0px;
  }
`;

const DetailsContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: 'BR Omny', sans-serif;
`;

const Label = styled.label`
  color: #929292;
  font-size: 16px;
  font-weight: 400;
  margin-bottom:5px;
  letter-spacing: -0.42px;
`;

const Title = styled.textarea`
  color: #000;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.96px;
  outline: none;
  height: 70px;
  border: none;
  line-height: 1.2;
  padding: 0px 0;
  resize: none; 
    &:focus {
    box-shadow: none;
  }
  &::placeholder {
    color: #000;
    opacity: 0.1;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  margin-top: 24px;
  min-height: 120px;
  width: 100%;
  flex-direction: column;
  font-family: 'BR Omny', sans-serif;
`;

const Description = styled.textarea`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 1.2;
  outline: none;
  border: none;
  padding: 0px 0;
  resize: none; /* Prevents the textarea from being resized */
  height: 120px; /* Set a fixed height similar to the min-height of the previous input */
  &::placeholder {
    color: #000;
    opacity: 0.1;
  }
  &:focus {
    box-shadow: none;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: -0.42px;
  justify-content: start;
  flex-grow: 1;
  max-width: 240px;
  font: 400 14px 'BR Omny', sans-serif;
    position: relative; /* Ensures the calendar is positioned correctly */
  z-index: 1; /* Make sure it's above any content */
`;

const DatePickerWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  margin-top: 4px;
  max-width: 100%;
  align-items: start;
  gap: -1px;
  color: #6b7280;
  line-height: 1;
  justify-content: start;
  overflow: visible; 
`;

const TimeLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'BR Omny', sans-serif;
  flex-grow: 1;
`;

const TimeValue = styled.span`
  color: #ccc;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.54px;
  margin-top: 4px;
`;

export default AdCreationForm;