import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../context/AppContext";
import styled from "styled-components"; 
import { startPhoneVerification } from "../services/verify";
import { isJson } from "../utils/transformApiErrors";
import Logo from "../components/UIcomponents/Logo";
import InputField from "../components/UIcomponents/InputField";
import Button from "../components/UIcomponents/Button";
import { registerUser } from "../services/auth";
import Link from "../components/UIcomponents/Link";
import { transformToUSPhoneNumber } from "../utils/transformPhoneNumber";
import logo from '../images/logoBiz.png'

const AccountCreationPage = () => {
  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const [registerApiValidatorResponse, setRegisterApiValidator] = useState({});
  const { appState, setAppState, updateOnboardingData } = useContext(AppContext);
  const navigate = useNavigate();

  const validatePhoneNumber = () => {
    const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts

    let userCreated = await registerUser({
      email: email,
      name: businessName,
      phone: phoneNumber
    });

    if ((userCreated && userCreated.code === 400) || userCreated.code === 500) {
      let validatorData;

      if(isJson(userCreated.message)) {
         let parsedData = JSON.parse(userCreated.message)
         setRegisterApiValidator(parsedData);
         validatorData = parsedData
      } else {
        validatorData = {
          details: userCreated.message || "Server Error"
        }
      }

      Object.keys(validatorData).forEach((key) => {
        toast.error(validatorData[key], {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    }

    if (userCreated && userCreated.user && userCreated.user.id) {
      await localStorage.removeItem('appState');
      await localStorage.removeItem('onboardingData');
      const verificationPhoneResponse = await startPhoneVerification({
        country_code: "1",
        phone_number: phoneNumber,
        via: "sms"
      })

      if (verificationPhoneResponse && verificationPhoneResponse.status === 'pending') {
        const updateResult =  await updateOnboardingData({
          userData: userCreated.user
        })
      
      
       if(updateResult) {
          await navigate("/verify",  {
            replace: true
          });
        }
      }
    }

    setLoading(false); // Set loading to false when submission ends
  }, [businessName, email, phoneNumber, navigate, setAppState]);

  const isDisabled = () => {
    return !businessName || !validateEmail() || !validatePhoneNumber();
  };

  const inputFields = [
    {
      placeholder: "Enter Business Name",
      type: "text",
      value: businessName,
      onChange: (e) => setBusinessName(e.target.value),
      error: errors.businessName,
    },
    {
      placeholder: "Enter Business Phone Number",
      type: "tel",
      value: phoneNumber,
      onChange: (e) => setPhoneNumber(transformToUSPhoneNumber(e.target.value)), // Assuming transformToUSPhoneNumber is defined
      error: errors.phoneNumber,
    },
    {
      placeholder: "Enter Business Email",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      error: errors.email,
    },
  ];

  return (
    <StyledMain>
      <Logo src={logo} alt="Company Logo" />
      <Title>Welcome</Title>
      <Description>
        To <strong>create your account</strong>, we need some essential
        information from you.
      </Description>
      <form style={{'width': '100%'}} onSubmit={handleSubmit}>
        {inputFields.map((field, index) => (
          <InputField key={index} {...field} />
        ))}
        <ButtonContainer>
        <Button isDisabled={isDisabled()} type="submit">
          {loading ? <Loader /> : "Continue"} {/* Conditionally render Loader */}
        </Button>
        </ButtonContainer>
        
      </form>
      <Link href="/login">Back to Login</Link>
    </StyledMain>
  );
};

const ButtonContainer = styled.div`
margin-top: 10px;

button {
  width: 100%;
}

`

const Loader = styled.div`
  border: 4px solid rgb(246 255 246 / 27%);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const StyledMain = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  max-width: 571px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #848484;
  font-weight: 400;
  line-height: 120%;
  padding: 40px 80px 62px;
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  margin-top: 25px;
  font: 600 48px BR Omny, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const Description = styled.p`
  color: #111;
  text-align: center;
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.48px;
  margin-top: 10px;
  margin-bottom: 40px;
`;

export default AccountCreationPage;
