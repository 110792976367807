import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card as TremorCard, Title as TremorTitle, Text as TremorText } from '@tremor/react';
import AnalyticsSection from './AnalyticsSelection';
import MetricsGrid from './MetricsGrid';
import MetricView from './MetricView';
import { AppContext } from '../../context/AppContext';

const MainContentContainer = styled.div`
  flex-grow: 1;
  padding: 48px;
  overflow-y: auto;
`;

const Header = styled.div`
  background-color: #fff;
  border-radius: 40px;
  padding: 40px;
  margin-bottom: 32px;

    @media screen and (max-width: 1500px) {
    width: 800px;
 }

 @media screen and (max-width: 1024px) {
  width: 800px;
 }

 @media screen and (max-width: 768px) {
    width: 700px;
 }
`;

const AnalyticsTitle = styled.p`
  color: #111;
  font-family: "BR Omny";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Title = styled(TremorTitle)`
  color: #000;
  font-family: "BR Omny";
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09rem;
`;

const Text = styled(TremorText)`
  color: #000;
  font-family: "BR Omny";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  letter-spacing: -0.03rem;
`;

const Container = styled.div`
  width: 1100px; /* Set a fixed width */
  margin: 0 auto;
  overflow: hidden; /* Prevent resizing */
  display: flex;
  flex-direction: column;
  gap: 20px;
    @media screen and (max-width: 1500px) {
    width: 800px;
 }

 @media screen and (max-width: 1024px) {
  width: 800px;
 }

 @media screen and (max-width: 768px) {
    width: 700px;
 }
`;


const AnalyticsTitleWrapper = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #FFF;
  margin-bottom: 32px;
`;

const Card = styled(TremorCard)`
  box-shadow: none;
    width: 1100px; 
margin: 0 auto;
  border-radius: 40px;

  @media screen and (max-width: 1500px) {
    width: 800px;
 }

  @media screen and (max-width: 1024px) {
    width: 800px;
  }
  
   @media screen and (max-width: 768px) {
    width: 700px;
  }
`;

const MainContent = () => {
  const { appState } = useContext(AppContext);
  const [selectedCard, setSelectedCard] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({});

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (appState.userData && appState.userData.businessAnalytics) {
        const businessAnalytics = appState.userData.businessAnalytics;
        setAnalyticsData(businessAnalytics);
        handleCardSelect(businessAnalytics.totalData[0]);
      }
    };

    fetchAnalyticsData();
  }, [appState.userData]);

  const handleCardSelect = (item) => {
    setSelectedCard(item);
  };

  return (
    <MainContentContainer>
      <Header>
        <Title>Dashboard</Title>
        <Text>Get insights into your business performance and activities.</Text>
      </Header>

      <AnalyticsTitleWrapper>
        <AnalyticsTitle>Your Analytics</AnalyticsTitle>
      </AnalyticsTitleWrapper>

      <Container>
      <Card>
        {selectedCard && <MetricView cardData={selectedCard} data={analyticsData} />}
      </Card>
      <MetricsGrid data={analyticsData} cardData={selectedCard}  onCardSelect={handleCardSelect} />
      </Container>

    </MainContentContainer>
  );
};

export default MainContent;
