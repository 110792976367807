import { useState, useEffect } from "react";
import * as signStyleComponent from "../forms/welcomeForms/signStyleComponent";

const InputCurrency = ({ onGetNumber, trackKeyUp, validation, value }) => {
  const [currencyNumber, setCurrencyNumber] = useState('');

  useEffect(() => {
    if (value) {
      setCurrencyNumber(value);
    }
  }, [value]);

  const formatNumber = (num) => {
    const inputValue = num;
    const numericValue = parseFloat(inputValue.replace(/[$,]/g, '')) || 0;
    let transformValue = numericValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    setCurrencyNumber(transformValue);
    onGetNumber(transformValue);
  };

  return (
    <>
      <signStyleComponent.Input
        id="revenueInput"
        itemName="revenue"
        type="text"
        value={currencyNumber}
        validation={validation}
        onBlur={(e) => formatNumber(e.target.value)}
        onKeyUp={() => trackKeyUp()}
        onChange={(e) => setCurrencyNumber(e.target.value)}
        placeholder='$'
      />
    </>
  );
};

export default InputCurrency;