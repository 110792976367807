import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Metric as TremorMetric, Text as TremorText, Flex, BadgeDelta as TremorBadgeDelta } from '@tremor/react';
import { DataGraph } from '../UIcomponents/dataGraph';
import './dashboard.css';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 48px; 

   @media screen and (max-width: 1500px)  {
      grid-template-columns: repeat(3, 1fr);
      margin: 0 auto;
      gap: 12px;
  }

    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto;
      gap: 12px;
    }
`;

const MetricCard = styled(Card)`
  padding: 24px;
  width: 230px;
  border-radius: 20px;
  box-shadow: none;
  border: ${({ selected }) => (selected ? '2px solid #FF746E' : '2px solid #ffffff')};
  background: ${({ selected }) => (selected ? '#FFFAFA' : 'white')};
  cursor: pointer;
   @media screen and (max-width: 1500px)  {
      width: 300px;
  }

   @media screen and (min-width: 1200px) and (max-width: 1365px) {
         width: 250px;
  }

   @media screen and (max-width: 1199px) {
      width: 350px;
  }
`;

const StyledText = styled(TremorText)`
  color: #111;
  font-family: "BR Omny";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const StyledMetric = styled(TremorMetric)`
  color: #000;
  text-align: center;
  font-family: "BR Omny";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.2px;
`;

const StyledBadgeDelta = styled(TremorBadgeDelta)`
  color: #189005;
  background: #DFFFDA;
`;

const DecreasedBadgeDelta = styled(TremorBadgeDelta)`
  color: #900505;
  background: #FFDADA;
`;

const MetricsGrid = ({ data, onCardSelect, cardData }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [deltaValues, setDeltaValues] = useState([]);

  useEffect(() => {
    if (data && data.totalData) {
      const initialDeltaValues = data.totalData.map((item, index) => ({
        key: item.key,
        value: Math.floor(Math.random() * 100),
        isIncrease: index % 2 === 0,
      }));
      setDeltaValues(initialDeltaValues);

      // Select the first card by default
      if (data.totalData.length > 0) {
        const firstItem = data.totalData[0];
        const firstDelta = initialDeltaValues[0];
        handleCardClick(firstItem, firstDelta.value, firstDelta.isIncrease);
      }
    }
  }, [data]);

  useEffect(() => {
    setSelectedCard(cardData);
  }, [cardData]);

  const handleCardClick = (item, percentage, isIncrease) => {
    setSelectedCard(item);
    onCardSelect({ ...item, percentage, isIncrease });
  };

  const getDeltaValue = (key) => {
    const delta = deltaValues.find(d => d.key === key);
    return delta ? delta.value : 0;
  };

  const isIncreasePositive = (key) => {
    const delta = deltaValues.find(d => d.key === key);
    return delta ? delta.isIncrease : true;
  };

  return (
    <Grid>
      {data && data.totalData && data.totalData.map((item, index) => (
        <MetricCard
          key={index}
          selected={selectedCard?.key === item.key}
          onClick={() => handleCardClick(item, getDeltaValue(item.key), isIncreasePositive(item.key))}
        >
          <div className="badge-wrapper">
            <StyledText>{item.name}</StyledText>
            {isIncreasePositive(item.key) ? (
              <StyledBadgeDelta
                deltaType="increase"
                isIncreasePositive={true}
                size="xs"
              >
                {getDeltaValue(item.key)} %
              </StyledBadgeDelta>
            ) : (
              <DecreasedBadgeDelta
                deltaType="decrease"
                isIncreasePositive={false}
                size="xs"
              >
                {getDeltaValue(item.key)} %
              </DecreasedBadgeDelta>
            )}
          </div>
          <Flex className="card-container" alignItems="top">
            <div style={{ marginBottom: "15px" }}>
              <StyledMetric className="text-base">{item.key === "totalSpend" ? Math.round(item.count) : item.count}</StyledMetric>
            </div>
          </Flex>
          <DataGraph kpiData={data} keyName={item.key} />
        </MetricCard>
      ))}
    </Grid>
  );
};

export default MetricsGrid;