import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../context/AppContext";
import { updateAnalytics } from "../../services/auth";
import { useCookies } from "react-cookie";
import success from '../../images/check-circle.svg'


const Loader = styled.div`
  border: 4px solid rgb(246 255 246 / 27%);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const CongratsCard = () => {
  const navigate = useNavigate()  
  const [loader, setLoading] = useState(false); 
  const { appState, setAppState, updateOnboardingData } = useContext(AppContext);
  const [cookies] = useCookies(['jwt']);

  const moveToDashboard = async () => {
   await setLoading(true)
   console.log(appState)
   if(appState && appState.userData) {
    const data = await updateAnalytics(appState.userData.id, cookies.jwt, '656528da2daa90d4d2d94de3', appState.userData.accountType)
    const updated = await updateOnboardingData({
       userData: data, loading: false
     });
     if(updated) {
        await setLoading(false)
        await navigate('/dashboard')
     }
   }
  }
  
  return (
    <Card>
      <SuccessIcon loading="lazy" src={success} alt="Success icon" />
      <Title>Congratulations!</Title>
      <Subtitle>You're all set up.</Subtitle>
      <Button onClick={() => moveToDashboard()}>          {loader ? <Loader /> : "Save & Get Started"} 
      </Button>
    </Card>
  );
};

const Card = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 571px;
  padding: 80px;
  border-radius: 40px;
  background-color: #fff;
  color: #111;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media (max-width: 991px) {
    padding: 40px 20px;
    // width: 100%;
    border-radius: 20px;
  }

  @media (max-width: 480px) {
          padding: 35px 40px;
        width: 320px;
  }
`;

const SuccessIcon = styled.img`
  width: 32px;
  height: 32px;
  aspect-ratio: 1;
  object-fit: contain;
  margin-top: 14px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    width: 24px;
    height: 24px;
  }
`;

const Title = styled.h2`
  margin-top: 16px;
  font: 28px BR Omny, sans-serif;
  text-align: center;
  letter-spacing: -0.84px;

  @media (max-width: 991px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  margin-top: 8px;
  font: 400 18px BR Omny, sans-serif;
  text-align: center;
  letter-spacing: -0.54px;

  @media (max-width: 991px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  padding: 16px 40px;
  border: none;
  border-radius: 10px;
  background-color: #ff746e;
  color: #fff;
  font: 16px/120% BR Omny, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ff5c55;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 991px) {
    padding: 14px 30px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 12px 24px;
    font-size: 12px;
  }
`;

export default CongratsCard;