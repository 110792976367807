import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import StyledLoginPage from "./pages/LoginPage";
import WelcomePage from "./pages/WelcomePage";
import ResetPasswordForm from "./components/forms/welcomeForms/ResetPassword";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import PhoneVerificationPage from "./pages/VerifyPhonePage";
import BusinessRegistrationPage from "./pages/BusinessRegistrationPage";
import BusinessRegistrationRevenue from "./pages/onboardingPages/business/businessRevenue";
import { BusinessCompanyType } from "./pages/onboardingPages/business/businessCompanyType";
import BusinessCompanyEIN from "./pages/onboardingPages/business/businessCompanyEin";
import { BusinessCompanyCategory } from "./pages/onboardingPages/business/businessCompanyCategory";
import BusinessGoal from "./pages/onboardingPages/business/businessGoal";
import AccountRegistrationPage from "./pages/AccountRegistrationPage";
import AccountConfirmation from "./pages/onboardingPages/account/accountConfirmation";
import AddressRegistration from "./pages/onboardingPages/account/addressConfirmation";
import CongratsCard from "./pages/onboardingPages/FinalPage";
import { checkAuthStatus, logOutUser } from "./services/auth";
import Dashboard from "./pages/DashboardPage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import ForgetPassword from "./components/forms/onboarding/ForgotPassword";
import ResetSuccess from "./components/forms/onboarding/ResetSuccess"
import VideoAdPitches from "./pages/VideoAdPitches";

const CompleteRegistrationPage = () => (
  <div>
    <h1>Please Complete Registration Process</h1>
    <p>You need to finish your registration before accessing the dashboard.</p>
  </div>
);

const useAuthStatus = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const authenticated = await checkAuthStatus();
        setIsAuthenticated(authenticated);
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    }
    verifyAuth();
  }, []);

  const onboardingData = JSON.parse(localStorage.getItem('onboardingData') || '{}');
  const isOnboarding = onboardingData?.userData?.isOnboarding;
  const hasBusinessAnalytics = onboardingData.userData?.hasOwnProperty('businessAnalytics');

  return { isAuthenticated, isLoading, isOnboarding, hasBusinessAnalytics };
};

const DashboardRoute = () => {
  const { isAuthenticated, isLoading, isOnboarding, hasBusinessAnalytics } = useAuthStatus();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && !hasBusinessAnalytics && !isOnboarding) {
    return <CompleteRegistrationPage />;
  }

  return <Dashboard />;
};

const ProtectedRoute = ({ children, redirectToDashboard = false }) => {
  const { isAuthenticated, isLoading, isOnboarding, hasBusinessAnalytics } = useAuthStatus();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (redirectToDashboard && isAuthenticated && hasBusinessAnalytics && isOnboarding) {
    return <Navigate to="/dashboard" replace />;
  }

  return children ? children : <Outlet />;
};

export default function App() {
  const [signIn, toggle] = useState(true);
  const { pathname } = useLocation();

  // useEffect(() => {
  //   const viewportMetaTag = document.querySelector('meta[name="viewport"]');

  //   if (window.innerWidth < 760) {
  //     viewportMetaTag.setAttribute('content', 'width=760, user-scalable=no');
  //   } else {
  //     viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1');
  //   }

  //   const handleResize = () => {
  //     if (window.innerWidth < 760) {
  //       viewportMetaTag.setAttribute('content', 'width=760, user-scalable=no');
  //     } else {
  //       viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1');
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);

  //   // Clean up the event listener on component unmount
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const userLogout = async () => {
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
    await logOutUser();
  }


  

  return (
    <>
      <ToastContainer />
      <div className="welcome-page-wrapper">
        <Routes>
          <Route path="/login" element={<StyledLoginPage />} />
          <Route path="/register" element={<WelcomePage />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/logout" element={<LogoutRoute userLogout={userLogout} />} />

          {/* Protected Routes with redirect to dashboard if conditions are met */}
          <Route element={<ProtectedRoute redirectToDashboard={true} />}>
            <Route path="/verify" element={<PhoneVerificationPage />} />
            <Route path="/business-registration" element={<BusinessRegistrationPage />} />
            <Route path="/business-registration/revenue" element={<BusinessRegistrationRevenue />} />
            <Route path="/business-registration/select-entity" element={<BusinessCompanyType />} />
            <Route path="/business-registration/ein" element={<BusinessCompanyEIN />} />
            <Route path="/business-registration/category" element={<BusinessCompanyCategory />} />
            <Route path="/business-registration/goal" element={<BusinessGoal />} />
            <Route path="/account-confirmation" element={<AccountRegistrationPage />} />
            <Route path="/account-confirmation/confirm" element={<AccountConfirmation />} />
            <Route path="/account-confirmation/address" element={<AddressRegistration />} />
            <Route path="/get-started" element={<CongratsCard />} />
            <Route path="/complete-registration" element={<CompleteRegistrationPage />} />
          </Route>

          {/* Dashboard route */}
          <Route path="/dashboard" element={<DashboardRoute />} />

          <Route path="/video-ad-pitches" element={<VideoAdPitches/>} />
          {/* Protected Routes for profile and settings */}
          <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/account" element={<SettingsPage />} />
            <Route path="/video-ad-pitches" element={<VideoAdPitches/>} />
          </Route>

          {/* Default route */}
          <Route path="/" element={<IndexRoute />} />

          {/* Catch-all route for non-existent routes */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </>
  );
}

const IndexRoute = () => {
  const { isAuthenticated, isLoading, isOnboarding, hasBusinessAnalytics } = useAuthStatus();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated && isOnboarding && hasBusinessAnalytics) {
    return <Navigate to="/dashboard" replace />;
  } else if (isAuthenticated && (!isOnboarding || !hasBusinessAnalytics)) {
    return <Navigate to="/register" replace />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

const LogoutRoute = ({ userLogout }) => {
  const navigate = useNavigate();
  const logoutPerformed = useRef(false);

  useEffect(() => {
    if (!logoutPerformed.current) {
      const performLogout = async () => {
        await userLogout();
        await navigate('/login', { replace: true });
      };

      performLogout();
      logoutPerformed.current = true;
    }
  }, [userLogout, navigate]);

  return <div>Logging out...</div>;
};
