import React, { useCallback, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import VerificationForm from "../components/forms/onboarding/verificationForm";
import Button from "../components/UIcomponents/Button";
import Link from "../components/UIcomponents/Link";
import { AppContext } from "../context/AppContext";
import { checkPhoneVerification, startPhoneVerification } from "../services/verify";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

const PhoneVerificationPage = () => {
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const { appState } = useContext(AppContext);
  const { userData, loading, error } = appState || {}; // Add a fallback to avoid accessing properties on undefined
  const navigate = useNavigate();

  const handleCodeChange = (index, value) => {
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
  };

  const handleResendCode = async () => {
    if (userData && userData.phone) {
      await startPhoneVerification({
        country_code: "1",
        phone_number: userData.phone,
        via: "sms"
      });
    }
  };

  const handleVerify = useCallback(async () => {
    if (userData && userData.phone) {
      let verificationResponse = await checkPhoneVerification({
        country_code: "1",
        phone_number: userData.phone,
        verification_code: verificationCode.join("")
      });

      if (verificationResponse.valid && verificationResponse.status === "approved") {
        await navigate('/business-registration', { replace: true });
      } else {
        toast.error('Code is Invalid. Please try again!', {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [verificationCode, userData, navigate]);

  useEffect(() => {
    if (verificationCode.every(code => code !== "")) {
      handleVerify();
    }
  }, [verificationCode, handleVerify]);

  const isCodeComplete = verificationCode.every(code => code !== "");

  return (
    <VerificationContainer>
      <Title>Verify your phone number</Title>
      <Subtitle>
        Enter the 4-digit code sent to <PhoneNumber>
          {loading && <div>Loading...</div>}
          {!loading && !userData && <div>Error: {error || "User data not available"}</div>}
          {!loading && userData && userData.phone && (
            <span>{userData.phone}</span>
          )}
        </PhoneNumber>
      </Subtitle>
      <VerificationForm
        verificationCode={verificationCode}
        onCodeChange={handleCodeChange}
      />
      <ResendContainer>
        <ResendText>Didn't get a code?</ResendText>
        <ResendLink
          onClick={handleResendCode}
          disabled={isResendDisabled}
          tabIndex={isResendDisabled ? -1 : 0}
        >
          Resend code
        </ResendLink>
      </ResendContainer>
      <Button isDisabled={!isCodeComplete} onClick={handleVerify} type="button">
        Continue
      </Button>
      <Link href="/register">Back</Link>
      <ToastContainer />
    </VerificationContainer>
  );
};

const VerificationContainer = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  max-width: 571px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 80px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  margin-top: 12px;
  font: 600 40px BR Omny, sans-serif;
`;

const Subtitle = styled.p`
  color: #111;
  text-align: center;
  font-family: BR Omny, sans-serif;
  font-weight: 400;
  letter-spacing: -0.48px;
  margin-top: 56px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const PhoneNumber = styled.span`
  font-weight: 600;
`;

const ResendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 295px;
  max-width: 100%;
  gap: 8px;
  text-align: center;
  letter-spacing: -0.48px;
  padding: 0 28px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ResendText = styled.p`
  color: #111;
  font-family: BR Omny, sans-serif;
  font-weight: 400;
`;

const ResendLink = styled.button`
  color: #ff746e;
  font-family: BR Omny, sans-serif;
  font-weight: 600;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
    text-decoration: none;
  }
`;

const VerifyButton = styled.button`
  font-family: BR Omny, sans-serif;
  border-radius: 10px;
  background-color: #ff746e;
  margin-top: 40px;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  padding: 16px 40px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 16px 20px;
  }
`;

const BackButton = styled.button`
  color: #ff746e;
  font-family: BR Omny, sans-serif;
  font-weight: 500;
  line-height: 120%;
  margin-top: 16px;
  background: none;
  border: none;
  cursor: pointer;
`;

export default PhoneVerificationPage;
