import React from 'react';
import styled from 'styled-components';
import { AreaChart, Card, Flex, Metric, Text } from '@tremor/react';

const ChartContainer = styled.div`
  height: 300px;
`;

const chartdata = [
  { date: '06-05-24', Views: 20 },
  { date: '06-10-24', Views: 35 },
  { date: '06-15-24', Views: 45 },
  { date: '06-20-24', Views: 40 },
  { date: '06-25-24', Views: 50 },
  { date: '06-30-24', Views: 55 },
  { date: '07-06-24', Views: 60 },
  { date: '07-13-24', Views: 58 },
  { date: '07-20-24', Views: 65 },
];

const AnalyticsSection = () => {
  return (
    <Card>
      <Flex alignItems="start">
        <div>
          <Text>Views</Text>
          <Metric>654</Metric>
        </div>
        <Text color="emerald">+74%</Text>
      </Flex>
      <ChartContainer>
        <AreaChart
          data={chartdata}
          index="date"
          categories={['Views']}
          colors={['rose']}
          showLegend={false}
          showYAxis={false}
          showGridLines={false}
          startEndOnly={true}
          showAnimation={true}
        />
      </ChartContainer>
    </Card>
  );
};

export default AnalyticsSection;