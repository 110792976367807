import React, { useRef, useEffect } from 'react';
import pencil from '../../images/edit-01.svg';
import trash from '../../images/trash-01.svg';
import styled, { css } from "styled-components";

const Popup = ({ editAction, deleteAction, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Call onClose when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <PopupMenu ref={popupRef}>
      <PopupMenuItemEdit onClick={editAction}>
        <ButtonEdit>
          <img src={pencil} alt="Edit icon" />
        </ButtonEdit>
        <EditText>Edit</EditText>
      </PopupMenuItemEdit>

      <Separator />

      <PopupMenuItemDelete onClick={deleteAction}>
        <ButtonDelete>
          <img src={trash} alt="Delete icon" />
        </ButtonDelete>
        <DeleteText>Delete</DeleteText>
      </PopupMenuItemDelete>
    </PopupMenu>
  );
};

const PopupMenu = styled.div`
  position: absolute;
  top: 56px;   
  right: 16px;
  background: #FFFBF7;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
   z-index: 1000;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 150px;
  height: 120px;
  align-items: center;
  justify-content: center;

 
 
`;

const PopupMenuItemEdit = styled.button`
  background: none;
  display: flex;
  align-items: center;
  border: none;
  color: #333;
  cursor: pointer; 
  height: 62px;
  width: 100%;
  border-radius: 10px 10px 0 0;

 
    &:hover {
      background-color: #e0e0e0;
  

    }
      
   
`;
const PopupMenuItemDelete = styled.button`
  background: none;
  display: flex;
  align-items: center;
  border: none;
  color: #333;
  cursor: pointer; 
  height: 60px;
  width: 100%;
  border-radius:  0 0 10px 10px;

 

    &:hover {
      background-color: #e0e0e0;

  

    }
      
   
`;



const Separator = styled.div`
width: 100%;
border-bottom: solid;
color: #e0e0e0;
height: 0;


 `;

const DeleteText = styled.span`
  position: relative;
  left: 1px;
  color: #F8443C;
  margin-left: 8px;

`;
const EditText = styled.span`
  position: relative;
  left: 1px;
  margin-left: 8px;

  
`;
const ButtonDelete = styled.button`
  border-radius: 5px;
   display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;


 ;  
`;

const ButtonEdit = styled.button`
  border-radius: 5px;
   display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

export default Popup;
