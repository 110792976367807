import React from "react";
import styled from "styled-components";

export const ProgressDot = ({ isActive }) => {
  return <Dot isActive={isActive} />;
};

const Dot = styled.div`
  background-color: ${props => props.isActive ? "#ff746e" : "#ececec"};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;