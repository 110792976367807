import React from 'react';
import styled from 'styled-components';
import { Switch } from '@tremor/react';
import status from '../../images/green-light.svg'
import idle from '../../images/idle.svg'
import VideoPlayer from '../UIcomponents/VideoPlayer';
import moment from 'moment';
const LaunchCard = ({ _id, title, description, expireDate, isActive, videoAdUrl, editAction }) => {
    
  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM DD, YYYY');
  };

  const handleEditAction = () => {
      editAction(_id)
  }
 
  return (
    <CardWrapper>
      <CardContent>
        <ImageColumn>
          <VideoWrapper>
            <VideoPlayer autoPlay={false} url={videoAdUrl} />
          </VideoWrapper>
        </ImageColumn>
        <InfoColumn>
          <CardInfo>
            <InfoSection>
              <EditButtonContainer>
                  <LiveStatusContainer>
                  <img src={isActive ? status : idle} />
                  <span>{isActive ? "Live" : "Idle"}</span>
                  </LiveStatusContainer>
                  <EditButton onClick={handleEditAction}>Edit</EditButton>               
              </EditButtonContainer>
            </InfoSection>
            <InfoSection>
              <Label>Title:</Label>
              <Title>{title}</Title>
            </InfoSection>
            <InfoSection>
              <Label>Description:</Label>
              <Description>{description}</Description>
            </InfoSection>
            <MetaInfoContainer>
              <MetaInfoItem>
                <Label>Expires:</Label>
                <Value>{formatDate(expireDate)}</Value>
              </MetaInfoItem>
              {/* <MetaInfoItem>
                <Label>Time Left:</Label>
                <Value>{timeLeft}</Value>
              </MetaInfoItem> */}
              <MetaInfoItem>
                <Label>Active:</Label>
                <SwitchWrapper>
                  <Switch id="activeSwitch" checked={isActive} disabled  />
                </SwitchWrapper>
 
              </MetaInfoItem>
            </MetaInfoContainer>
          </CardInfo>
        </InfoColumn>
      </CardContent>

    </CardWrapper>
  );
};



const EditButtonContainer = styled.div` 
display: flex;
justify-content: space-between;
`

const LiveStatusContainer = styled.div` 

display: flex;

span {
 margin-left: 4px;
}

` 

const CardWrapper = styled.article`
  border-radius: 40px;
  background-color: #fff;
  padding-right: 43px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardContent = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    // flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 34%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 40px 0 0 40px;
  position: relative;
  aspect-ratio: 0.71;
  flex-grow: 1;
  justify-content: center;


`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 66%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const CardInfo = styled.div`
  display: flex;
  margin-top: 70px;
  flex-direction: column;
  
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }

  @media screen and (max-width: 1440px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const EditButton = styled.button`
  letter-spacing: -0.48px;
  color: #FF746E;
  align-self: flex-start;
  font: 500 16px BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const InfoSection = styled.div`
  margin-top: 24px;
`;

const Label = styled.span`
  letter-spacing: -0.42px;
  color: #929292;
  font: 400 14px BR Omny, sans-serif;
  display: block;
  margin-bottom: 5px;
`;

const Title = styled.h1`
  letter-spacing: -0.96px;
  color: #000;
  font: 600 32px BR Omny, sans-serif;
  margin: 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Description = styled.p`
  letter-spacing: -0.48px;
  color: #000;
  font: 400 16px BR Omny, sans-serif;
  margin: 5px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MetaInfoContainer = styled.div`
  display: flex;
  margin-top: 24px;
  padding-right: 80px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const MetaInfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Value = styled.span`
  letter-spacing: -0.54px;
  color: #000;
  margin-top: 4px;
  font: 600 18px BR Omny, sans-serif;
`;

const SwitchWrapper = styled.div`
  margin-top: 8px;
`;

export default LaunchCard;