import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { EditMyInformation } from '../settings/SettingsInfo';
import Button from '../UIcomponents/Button';
import { updateProfile } from '../../services/auth';
import { AppContext } from '../../context/AppContext';

const PersonalInfo = ({ name, email, phoneNumber }) => {

  const { appState, updateOnboardingData } = useContext(AppContext);

  const [editClicked, setEditClick] = useState(false);
  const [bussiness_name, setBussinessName] = useState("");
  const [editEmail, setEmail] = useState("");
  const [editPhoneNumber, setPhoneNumber] = useState("");

  const handleEditClick = () => {
    setEmail(email)
    setPhoneNumber(phoneNumber)
    setBussinessName(name)
    setEditClick(true);
  };

  const handleDiscardClick = () => {
    setEditClick(false);
  };


  const connectAPI = async (idUser) => {
    try {
      const { data, status } = await updateProfile(
        {
          "email": editEmail,
          "name": bussiness_name, 
          "phone": editPhoneNumber
        }
      , idUser);

      if (data && status === 200) {

        setEditClick(false);
 
        await updateOnboardingData({
          userData: data.user, loading: false
        });
      
      }
    } catch (error) {
      console.error("Failed to update profile information", error);
    }
  };

  const id = appState.userData.id;

  return (
    <InfoContainer>
      <SectionTitle>My Information</SectionTitle>

      {!editClicked ? (
          <>
          
      <InfoItem label="Name" value={name} />
      <InfoItem label="Email" value={email} />
      <InfoItem label="Phone Number" value={phoneNumber} />
          </>
        ) : ( 
          <EditMyInformation
          title={"Profile Info"}
          name={bussiness_name}
          email={editEmail}
          phone={editPhoneNumber}
          setBusiness={setBussinessName}
          setEmail={setEmail}
          setPhoneNumber={setPhoneNumber}
          />
        )
      }
      
      {
        editClicked ? (
          <div style={{display: 'flex', marginTop: '30px'}}> 
            <DiscardButton onClick={handleDiscardClick}>Discard</DiscardButton>
            <SaveContainter>
            <Button type="submit" size="xsmall" disabled={false} onClick={() => connectAPI(id)}>Save Changes</Button>
            </SaveContainter>
            </div>
        ) : (
          <EditButton onClick={handleEditClick}>Edit</EditButton>
        )
      }
   
   
      {/* <InfoItem label="Business Name:" value={businessName} /> */}
    </InfoContainer>
  );
};

const SaveContainter = styled.div`
    margin-left: 18px;
    display: flex;

    `;
 
//discard button was made with the Link properties, but link takes href, so here is being treated as a button 
const DiscardButton = styled.button`
  color: #ff746e;
  background: none;
  font-family: BR Omny, sans-serif;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 1024px) {
    // margin-top: 40px;
   }
`;

const EditButton = styled.button`
  letter-spacing: -0.48px;
  color: #FF746E;
  align-self: flex-start;
  font: 500 16px BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 80px;
  margin-top: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  letter-spacing: -0.42px;
`;

const SectionTitle = styled.h3`
  letter-spacing: -0.6px;
  font: 600 20px BR Omny, sans-serif;
  margin-bottom: 19px;
`;

const InfoItem = ({ label, value }) => (
  <>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </>
);

const Label = styled.div`
  color: #9e9e9e;
  font-family: BR Omny, sans-serif;
  margin-top: 16px;
`;

const Value = styled.div`
  letter-spacing: -0.48px;
  margin-top: 4px;
  font: 16px BR Omny, sans-serif;
`;

export default PersonalInfo;