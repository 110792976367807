import React from "react";
import styled from "styled-components";
import { BadgeDelta as TremorBadgeDelta } from '@tremor/react';

const StyledBadgeDelta = styled(TremorBadgeDelta)`
  color: #189005;
  border-radius: 20px;
  background: #DFFFDA;
  padding: 8px 12px;
  position: absolute; 
  top: 0; 
  width: 75px;
  right: 0;
`;

const DecreasedBadgeDelta = styled(TremorBadgeDelta)`
  color: #900505;
  background: #FFDADA;
  padding: 8px 12px;
  position: absolute; 
  top: 0; 
  right: 0;
  width: 75px;
`;

const MetricCard = ({ name, count, keyName, isIncrease, percentage }) => {
  return (
    <CardWrapper>
      <CardHeader>Metric</CardHeader>
      <MetricContent>
        <MetricValues>
          <MetricLabel>{name}</MetricLabel>
          <MetricNumber>{ keyName === "totalSpend" ? Math.round(count) : count}</MetricNumber>
        </MetricValues>
        <PercentageTag>
        {isIncrease ? (
              <StyledBadgeDelta
                deltaType="increase"
                isIncreasePositive={true}
                size="xs"
              >
               {percentage} %
              </StyledBadgeDelta>
            ) : (
              <DecreasedBadgeDelta
                deltaType="decrease"
                isIncreasePositive={false}
                size="xs"
              >
                {percentage} %
              </DecreasedBadgeDelta>
            )}
        </PercentageTag>
      </MetricContent>
      <Divider />
      <KeySection>
        <KeyHeader>Key</KeyHeader>
        <KeyItem>
          <KeyDot />
          <KeyText>Total Number of {name}</KeyText>
        </KeyItem>
      </KeySection>
    </CardWrapper>
  );
};

const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  width: 23.5%;
  @media (max-width: 1199px) {
    width: 100%;
  }
`;

const CardHeader = styled.h2`
  color: #adadad;
  letter-spacing: -0.42px;
  font: 400 14px BR Omny, sans-serif;
`;

const MetricContent = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 20px;
  justify-content: space-between;
  position: relative; // Added this line
  @media (max-width: 1199px) {
    flex-wrap: wrap;
  }
`;

const MetricValues = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetricLabel = styled.span`
  color: #111;
  letter-spacing: -0.72px;
  font: 400 24px BR Omny, sans-serif;
`;

const MetricNumber = styled.span`
  color: #000;
  text-align: center;
  letter-spacing: -1.44px;
  font: 600 48px BR Omny, sans-serif;
  @media (max-width: 1199px) {
    font-size: 40px;
            text-align: left;
  }
`;

const PercentageTag = styled.div`
  position: relative; // Added this line
`;

const Divider = styled.hr`
  background-color: #e6e6e6;
  margin: 26px 0;
  height: 1px;
  border: none;
`;

const KeySection = styled.div`
`;

const KeyHeader = styled.h3`
  color: #adadad;
  letter-spacing: -0.42px;
  font: 400 14px BR Omny, sans-serif;
`;

const KeyItem = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 4px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  align-items: center;
`;

const KeyDot = styled.span`
  background-color: #ff746e;
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;

const KeyText = styled.span`
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.48px;
`;

export default MetricCard;