import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { Card, Divider } from "@tremor/react";
import Button from "../../../components/UIcomponents/Button";
import Link from "../../../components/UIcomponents/Link";
import AddressForm from "../../../components/forms/onboarding/addressForm";
import { businessIdentify, addressRegistration, updateAnalytics } from "../../../services/auth";
import { generateAppleMapsToken, getMapKitAddress } from "../../../services/appleApis";
import { AppContext } from "../../../context/AppContext";
import { ProgressDot } from "../../../components/forms/onboarding/progressDot";

// Styled-components
export const StyledMain = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  max-width: 571px;
  flex-direction: column;
  align-items: center;
  padding: 44px 40px 80px;

  @media (max-width: 991px) {
    padding: 20px;
    border-radius: 20px;
  }
`;

export const CompanyName = styled.h1`
  color: #ffb9b6;
  text-align: center;
  letter-spacing: -0.48px;
  margin-top: 43px;
  font: 500 16px BR Omny, sans-serif;

  @media (max-width: 991px) {
    margin-top: 30px;
    font-size: 14px;
  }
`;

export const Title = styled.h2`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 17px;
  font: 400 24px BR Omny, sans-serif;

  @media (max-width: 991px) {
    font-size: 20px;
    margin-top: 12px;
  }
`;

export const AddressWrapper = styled.div`
  width: 100%;
  max-width: 404px;
  margin-top: 39px;

  @media (max-width: 991px) {
    margin-top: 25px;
  }
`;

export const SubTitle = styled.p`
  margin-bottom: 10px;

  color: #111;

text-align: center;
font-family: "BR Omny";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.72px;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const Text = styled.p`
color: #111;

text-align: center;
font-family: "BR Omny";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.72px;

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

export const Subtitle = styled.span`
  color: #9c9c9c;
  font: 400 14px BR Omny, sans-serif;

  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

export const CardText = styled.p`
  font: 600 16px BR Omny, sans-serif;
  color: #111;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

export const CardSubText = styled.p`
  font: 400 14px BR Omny, sans-serif;
  color: #9c9c9c;

  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px
`;

const Loader = styled.div`
  border: 4px solid rgb(246 255 246 / 27%);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// AddressConfirmation Component
const AddressConfirmation = () => {
    const progressSteps = [false, true];

    const { appState, setAppState, updateOnboardingData } = useContext(AppContext);
    const { userData, error } = appState;
    const [loader, setLoading] = useState(false); 
  const [mailingAddress, setMailingAddress] = useState({ name: "", street: "", city: "", state: "", zipCode: "" });
  const [physicalAddress, setPhysicalAddress] = useState({ name: "", street: "", city: "", state: "", zipCode: "", fullValue: "" });
  const [existedAddress, setExistedAddresses] = useState([]);
  const [selectedPhysicalAddress, setAddress] = useState({});
  const [isActiveAddress, setIsAddressExist] = useState(false)
  const [cookies] = useCookies(['jwt']);
  const navigate = useNavigate();

  const handleMailingLocationSelect = async (selectedAddress) => {
    const { name, street, city, state, zipCode } = await selectedAddress;
    setMailingAddress({ ...mailingAddress, street, city, state, zipCode, name });
  };

  const handlePhysicalLocationSelect = async (selectedAddress, inputValue) => {
    const { name, street, city, state, zipCode } = await selectedAddress;
    setPhysicalAddress({ ...physicalAddress, street, city, state, zipCode, name, fullValue: inputValue });
  };

  const handlePhysicalInputChange = (event) => {
    const { name, value } = event.target;
    setPhysicalAddress({ ...physicalAddress, [name]: value });
  };

  const handleMailInputChange = (event) => {
    const { name, value } = event.target;
    setMailingAddress({ ...mailingAddress, [name]: value });
  };

  const checkAllValuesFilled = (address) => {
    for (const key in address) {
      if ((key !== "name" && key !== "fullValue") && address[key] === "") {
        return false;
      }
    }
    return true;
  };

  const isAddressFilled = () => {
    return checkAllValuesFilled(mailingAddress) & checkAllValuesFilled(physicalAddress);
  };

  const redirectToAddresIdentification = async () => {
    // await updateAddress({ ...physicalAddress });   
    await setLoading(true)
    const MapKitToken = await generateAppleMapsToken();
    if (MapKitToken.accessToken) {

      const mapKitData = await getMapKitAddress(MapKitToken.accessToken, physicalAddress.fullValue);
      const coordinates = await transformCoordinates(mapKitData);
      const addressResponse = await businessIdentify(cookies.jwt, coordinates);
      if (addressResponse.length) {
        await setIsAddressExist(true)
        await setExistedAddresses(addressResponse);
        await setLoading(false)
      } else {
         await setIsAddressExist(false)
        await updateAddress({ ...physicalAddress, ...coordinates });   
        await setLoading(false)
      }
      
    } else {
      await toast.error(MapKitToken.error.message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

 const moveToGetStarted = async () => {
     await setLoading(true);
     await updateAddress(selectedPhysicalAddress);
     await setLoading(false);
  }

  const transformCoordinates = (mapData) => {
    const { latitude, longitude } = mapData && mapData.results[0].coordinate;
    return {
      lat: latitude.toPrecision(6),
      lon: longitude.toPrecision(6)
    };
  };

  const selectAddress = (item) => {
    setAddress((prevAddress) => {
      return prevAddress === item ? {} : item;
    });
  };

  const updateAddress = async (address) => {
    let updateAddress = await addressRegistration({
      physicalAddress: address,
      mailingAddress: mailingAddress,
      password: userData.password,
      email: userData.email,
      isOnboarding: true,
      accountType: 2
    }, appState.userData.id, cookies.jwt);
    if (updateAddress.code && updateAddress.message) {
      await toast.error(updateAddress.message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      await updateOnboardingData({
        userData: updateAddress
      })
      await navigate('/get-started', { replace: true });
    }
  };

  const moveToSelectAdress = async() => {
    await setIsAddressExist(false)
  }

  const moveToRegister = async () => {
    await navigate("/register");
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
  }

  return (
    <StyledMain>
         <Header>
        <HeaderTitle>Account Confirmation</HeaderTitle>
        <ProgressBar>
          {progressSteps.map((isActive, index) => (
            <ProgressDot key={index} isActive={isActive} />
          ))}
        </ProgressBar>
        <ExitButton onClick={moveToRegister}>Exit</ExitButton>
      </Header>

      <ToastContainer />
      {isActiveAddress ? (
        <>
          <AddressWrapper>
            <SubTitle>We found {existedAddress.length} listing in our system!</SubTitle>
            <Text> Let us know if it’s you!</Text>
            <Divider />
            {existedAddress.map((item, i) => (
              <Card key={i} onClick={() => selectAddress(item)} style={{ cursor: 'pointer', height: '130px', border: item === selectedPhysicalAddress ? '1px solid #FFB9B6' : '' }} className="my-2.5 mx-auto max-w-xs">
                <CardText>{item.name}, {item.street}</CardText>
                <CardSubText>{item.state}</CardSubText>
                <CardSubText>{item.city}</CardSubText>
                <CardSubText>{item.zipCode}</CardSubText>
              </Card>
            ))}
          </AddressWrapper>
          <NavigationButtons>
        <Button onClick={moveToGetStarted} isDisabled={!selectedPhysicalAddress || Object.keys(selectedPhysicalAddress).length === 0}  type="submit">
          {loader ? <Loader /> : "Continue"} 
        </Button>
        <StyledButton onClick={moveToSelectAdress}>
    Back
  </StyledButton>
      </NavigationButtons>
        </>
      ) : (
        <>
          <CompanyName>{userData.name}</CompanyName>
          <Title>What's your address?</Title>
          <AddressForm
            title="Physical Address"
            address={physicalAddress}
            onSelect={handlePhysicalLocationSelect}
            onChange={handlePhysicalInputChange}
          />
          <AddressForm
            title="Mailing Address"
            address={mailingAddress}
            onSelect={handleMailingLocationSelect}
            onChange={handleMailInputChange}
          />
              <NavigationButtons>
        <Button onClick={redirectToAddresIdentification} isDisabled={!isAddressFilled()} type="submit">    {loader ? <Loader /> : "Continue"} </Button>
        <Link  href="/account-confirmation">Restart Account Confirmation</Link>
      </NavigationButtons>
        </>
      )}
  
    </StyledMain>
  );
};

const StyledButton = styled.button`
  font-family: BR Omny, sans-serif;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  transition: background-color 0.3s;
    color: #ff746e;
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
  width: 130px;
`;

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const HeaderTitle = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

export default AddressConfirmation;