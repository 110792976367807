import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { updateProfile } from '../../services/auth';
import { AppContext } from '../../context/AppContext';
import Button from '../UIcomponents/Button';
import { EditBusinessInformation } from '../settings/SettingsInfo';

const BusinessInfo = ({ revenue, entityType, ein, category, goals }) => {
  const [editClicked, setEditClick] = useState(false);
  const [goalInput, setGoal] = useState("");
  const [businessRevInput, setBusinessRevData] = useState("");


  const { appState, updateOnboardingData } = useContext(AppContext);
  const id = appState.userData.id;

  const handleEditClick = () => {
    setEditClick(true);
  };

  const handleDiscardClick = () => {
    setEditClick(false);
    setGoal(goals);
    setBusinessRevData(revenue);
  };

  useEffect(() => {
    setGoal(goals);
    setBusinessRevData(revenue);
  }, [goals, revenue]); // Add goals and revenue as dependencies
  


  const updateBusinessInfo = async (id) => {
    try {
      const { data, status } = await updateProfile(
        {
          businessGoal:  goalInput,
          revenue: businessRevInput
        }
      , id);

      if (data && status === 200) {

        setEditClick(false);
 
        await updateOnboardingData({
          userData: data.user, loading: false
        });
      
      }
    } catch (error) {
      console.error("Failed to update profile information", error);
    }
  }

  return (
    <InfoContainer>
      <SectionTitle>Business Information</SectionTitle>
      <InfoItem label="EIN" value={ein} />
      <InfoItem label="Legal Entity Type" value={entityType} />
      <InfoItem label="Business Category:" value={category} />
      {
        editClicked ? (
          <EditBusinessInformation revenue={businessRevInput} businessGoal={goalInput} setBusinessRevenue={setBusinessRevData} setBusinessGoal={setGoal} />
        ) : (
          <>
          <InfoItem label="Current Business Revenue" value={revenue} />
          <InfoItem label="Business Goals:" value={goals} />
          </>

        )
      }


      {
        editClicked ? (
          <div style={{display: 'flex', marginTop: '30px'}}> 
            <DiscardButton onClick={handleDiscardClick}>Discard</DiscardButton>
            <SaveContainter>
            <Button type="submit" size="xsmall" disabled={false} onClick={() => updateBusinessInfo(id)}>Save Changes</Button>
            </SaveContainter>
            </div>
        ) : (
          <EditButton onClick={handleEditClick}>Edit</EditButton>
        )
      }
    </InfoContainer>
  );
};


const SaveContainter = styled.div`
    margin-left: 18px;
    display: flex;

    `;
 

const DiscardButton = styled.button`
  color: #ff746e;
  background: none;
  font-family: BR Omny, sans-serif;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 1024px) {
    // margin-top: 40px;
   }
`;

const EditButton = styled.button`
  letter-spacing: -0.48px;
  color: #FF746E;
  align-self: flex-start;
  font: 500 16px BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 80px;
  margin-top: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
  letter-spacing: -0.42px;
`;

const SectionTitle = styled.h3`
  color: #000;
  letter-spacing: -0.6px;
  font: 600 20px BR Omny, sans-serif;
  margin-bottom: 19px;
`;

const InfoItem = ({ label, value }) => (
  <>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </>
);

const Label = styled.div`
  font-family: BR Omny, sans-serif;
  margin-top: 16px;
`;

const Value = styled.div`
  color: #000;
  letter-spacing: -0.48px;
  margin-top: 4px;
  font: 16px BR Omny, sans-serif;
`;

export default BusinessInfo;