import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ProgressDot } from "../../../components/forms/onboarding/progressDot";
import Button from "../../../components/UIcomponents/Button";
import Link from "../../../components/UIcomponents/Link";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { AppContext } from "../../../context/AppContext";

const businessCategories = [
    { value: '722511', label: '722511: Full-Service Restaurants' },
    { value: '722513', label: '722513: Limited-Service Restaurants' },
    { value: '722410', label: '722410: Bars and Nightclubs' },
    { value: '722414', label: '722414: Drinking Places (Alcoholic Beverages)' },
    { value: '722515', label: '722515: Brewpubs' }
]

export const BusinessCompanyCategory = () => {
  const progressSteps = [false, false, false, true, false];
  const { appState, setAppState, updateOnboardingData } = useContext(AppContext);
  const { userData, loading, error } = appState;
  const [businessCategory, setBusinessCategory] = useState(null);
  const [registerApiValidatorResponse, setRegisterApiValidator] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.businessCategory) {
      const defaultOption = businessCategories.find(option => option.value === userData.businessCategory);
      setBusinessCategory(defaultOption);
    }
  }, [userData]);

  const moveToNext = async () => {
    await updateOnboardingData({
      userData: { ...userData, businessCategory: businessCategory.value }
    });
    await navigate('/business-registration/goal');
  }

  const handleChange = (selectedOption) => {
    setBusinessCategory(selectedOption);
    setAppState(prevState => ({
      ...prevState,
      userData: {
        ...prevState.userData,
        businessCategory: selectedOption.value
      }
    }));
  }

  const moveToRegister = async () => {
    await navigate("/register");
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
  }

  return (
    <RegistrationContainer>
      <Header>
        <HeaderTitle>Business Registration</HeaderTitle>
        <ProgressBar>
          {progressSteps.map((isActive, index) => (
            <ProgressDot key={index} isActive={isActive} />
          ))}
        </ProgressBar>
        <ExitButton onClick={moveToRegister}>Exit</ExitButton>
      </Header>
      <Main>
        <BusinessName>{userData.name}</BusinessName>
        <SectionTitle>Select your business category:</SectionTitle>

        <Select
          itemName="legalEntityType"
          className="basic-single"
          validation={registerApiValidatorResponse}
          classNamePrefix="select"
          placeholder='Choose an option below'
          isDisabled={false}
          isSearchable={false}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              textAlign: 'left',
              backgroundColor: '#FF746E',
              color: '#848484',
              borderColor: state.isFocused ? '#FFB2A7' : baseStyles.borderColor,
              boxShadow: state.isFocused ? '0 0 0 1px #FFB2A7' : baseStyles.boxShadow,
              border: registerApiValidatorResponse.hasOwnProperty('legalEntityType') ? 'solid 1px #FF746E' : 'solid 1px #FF746E',
              '&:hover': {
                borderColor: '#FFB2A7'
              }
            }),
            option: (base, state) => ({
              ...base,
              textAlign: 'left',
              borderBottom: "1px solid #FFFBF7",
              color: '#848484',
              backgroundColor: state.isSelected ? '#FFFBF7' : state.isFocused ? '#FFFBF7' : 'white',
              borderBottom: '1px solid #E4E4E4',
              width: '100%'
            }),
            singleValue: (base) => ({
              ...base,
              color: '#848484'
            })
          }}
          name="color"
          onChange={handleChange}
          options={businessCategories}
          value={businessCategory}
        />

        <NavigationButtons>
          <Button isDisabled={!businessCategory} onClick={(e) => moveToNext(e)} type="button">
            Next
          </Button>
          <Link href="/business-registration/ein">Back</Link>
        </NavigationButtons>
      </Main>
    </RegistrationContainer>
  );
};

const RegistrationContainer = styled.div`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 970px;
  flex-direction: column;
  align-items: center;
  padding: 54px 41px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
`;

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const HeaderTitle = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BusinessName = styled.h2`
  color: #ffb9b6;
  text-align: center;
  letter-spacing: -0.48px;
  margin-top: 88px;
  font: 500 16px BR Omny, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h3`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 17px;
  font: 400 24px BR Omny, sans-serif;
`;

const NavigationButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;