import React from "react";
import styled from "styled-components";
import MetricCard from "./MetricsCard";
import Chart from "./Chart";

const MetricView = ({
  data,
  cardData
}) => {
  console.log(cardData)
  return (
    <Container>
      <ContentWrapper>
        <MetricCard name={cardData.name} count={cardData.count} keyName={cardData.key} isIncrease={cardData.isIncrease} percentage={cardData.percentage} />
        <Chart performance={data.analytics} selectedCount={cardData.key} />
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.section`
  border-radius: 40px;
  background-color: #fff;
  padding: 71px 25px;
  @media (max-width: 1199px) {
    padding: 40px 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
  }

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;

export default MetricView;