import Sidebar from '../components/dashboard/Sidebar';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import { Title as TremorTitle, Text as TremorText, TabGroup, TabList, TabPanels, TabPanel } from '@tremor/react';
import SettingsSidebar from '../components/settings/SettingsSidebar';
import {SettingsInfo, EditInformation} from '../components/settings/SettingsInfo';
 import Button from '../components/UIcomponents/Button';
 import { updateProfile } from '../services/auth';
 
const MainContentContainer = styled.div`
  flex-grow: 1;
  padding: 48px;
  overflow-y: auto;
`;
const Header = styled.div`
  background-color: #fff;
  border-radius: 40px;
  padding: 40px;
  margin-bottom: 32px;

  @media screen and (max-width: 1500px) {
    width: 800px;
 }

  @media screen and (max-width: 1024px) {
    width: 800px;
  }
  

  
   @media screen and (max-width: 768px) {
    width: 700px;
  }

`;
const AnalyticsTitle = styled.p`
  color: #111;
  font-family: "BR Omny";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
`;
const Title = styled(TremorTitle)`
  color: #000;
  font-family: "BR Omny";
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09rem;
`;
const Text = styled(TremorText)`
  color: #000;
  font-family: "BR Omny";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  letter-spacing: -0.03rem;
`;
const Container = styled.div`
  width: 1100px;
  margin: 0 auto;
  overflow: hidden; /* Prevent resizing */
  display: flex;
  flex-direction: column;
  gap: 20px;


`;
const AnalyticsTitleWrapper = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #FFF;
  margin-bottom: 32px;
`;
const DashboardContainer = styled.div`
  display: flex;
  background-color: #FCF8F4;
  min-height: 100vh;
`;
const InfoContainer = styled.div`
  border-radius: 40px;
  background-color: #fff;
  margin-top: 32px;
  height: 512px;
  width: 1100px;
    padding-top: 64px;
    padding-left: 24px;
    padding-bottom: 50px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
  @media screen and (max-width: 1500px) {
    width: 800px;
  }

  @media screen and (max-width: 1024px) {
    width: 800px;
  }
  

   @media screen and (max-width: 768px) {
    width: 700px;
  }
`;

const EditButtonForResponsive =  styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    margin-left: 20px;
   }

   @media screen and (max-width: 768px) {
    display: block;
    margin-left: 0;
   }
`

const accountTypes = [
  { value: 0, label: 'Guest' },
  { value: 1, label: 'CyaSponsor' },
  { value: 2, label: 'CyaSpot' }
];


const SettingsPage = () => {
  const { appState, updateOnboardingData } = useContext(AppContext);
  const [profileData, setProfileData] = useState();
  const [accountData, setAccountData] = useState();
  const [subscriptionInfo, setSubscriptionInfo] = useState();
  const [editClicked, setEditClick] = useState(false);
  const [activeTab, setActiveTab] = useState('account'); // Set default tab value to "profile"



  const [bussiness_name, setBussinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (appState && appState.userData) {
      const { name, email, phone, accountType} = appState.userData;
      let type = accountTypes.find(item => item.value === accountType);
       
      console.log(appState.userData);
      setBussinessName(name);
      setEmail(email);
      setPhoneNumber(phone);

      setProfileData([
        { label: "Name", value: name },
        { label: "Email", value: email },
        { label: "Phone Number", value: phone }
      ]);
      setAccountData([
        { label: "Email", value: email },
        { label: "Password", value: "••••••••••" }
      ]);
      setSubscriptionInfo([
        { label: "Plan", value: type.label },
        { label: "Billing Cycle", value: "July 24, 2024" }
      ]);
    }
  }, [appState]);


  const handleEditClick = () => {
    setEditClick(true);
  };

  const handleTabChange = (index) => {
    const tabMap = ['profile', 'account', 'subscription'];
    const selectedTab = tabMap[index];
    setActiveTab(selectedTab);

     if (selectedTab !== 'profile') {
      setEditClick(false);
    }
  };

  const handleDiscardClick = () => {
    setEditClick(false);
  };


  const connectAPI = async (idUser) => {
    try {
      const { data, status } = await updateProfile(
        {
          "email": email,
          "name": bussiness_name, 
          "phone": phoneNumber
        }
      , idUser);

      if (data && status === 200) {

        setEditClick(false);
 
        await updateOnboardingData({
          userData: data.user, loading: false
        });
      
      }
    } catch (error) {
      console.error("Failed to update profile information", error);
    }
  };
  //send id for the API 
  const id = appState.userData.id;

   return (
    <DashboardContainer>
      <Sidebar />
      <MainContentContainer>
        <Header>
          <Title>Account</Title>
          <Text>Manage your account.</Text>
        </Header>

        <AnalyticsTitleWrapper>
          <AnalyticsTitle>Account Preferences</AnalyticsTitle>
        </AnalyticsTitleWrapper>

          <InfoContainer>
            <ProfileContainer>
              <ProfileContent>
                <TabGroup className='settings-tabs' defaultValue="profile" onIndexChange={handleTabChange}>
                  <TabList className='settings-tab-list'>
                    <SettingsSidebar />
                  </TabList>
                  <TabPanels>
                    {/* <TabPanel value={0}>
                      {editClicked ? (
                        <EditInformation
                          title={"Profile Info"}
                          name={bussiness_name}
                          email={email}
                          phone={phoneNumber}
                          setBusiness={setBussinessName}
                          setEmail={setEmail}
                          setPhoneNumber={setPhoneNumber}
                          />
                      ) : (
                        <SettingsInfo title={'Profile Info'} accountData={profileData} />
                      )}

              {activeTab === 'profile' && ( 
                   <EditButtonForResponsive>
                    {!editClicked ?  (
                      <EditButton onClick={handleEditClick}>Edit</EditButton>
                    ) : (
             
                      <div style={{display: 'flex'}}> 
                        <DiscardButton onClick={handleDiscardClick}>Discard</DiscardButton>
                        <SaveContainter>
                        <Button type="submit" size="xsmall" disabled={false} onClick={() => connectAPI(id)}>Save Changes</Button>
                        </SaveContainter>
                        </div>
                    )}
                  </EditButtonForResponsive> 
                )}
                    </TabPanel> */}
                    <TabPanel value= {1}>
                      <SettingsInfo title={'Account Details'} accountData={accountData} />
                    </TabPanel>
                    <TabPanel value={2}>
                      <SettingsInfo title={'Subscription Information'} accountData={subscriptionInfo} />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
 
                {/* {activeTab === 'profile' && ( 
          
                   <EditButtonContainer2>
                    {!editClicked ?  (
                      <EditButton onClick={handleEditClick}>Edit</EditButton>
                    ) : (
             
                      <EditButtonContainer2> 
                        <DiscardButton onClick={handleDiscardClick}>Discard</DiscardButton>
                        <SaveContainter>
                        <Button type="submit" size="xsmall" disabled={false} onClick={() => connectAPI(id)}>Save Changes</Button>
                        </SaveContainter>
                        </EditButtonContainer2>
                    )}
                  </EditButtonContainer2> 
                )} */}
              </ProfileContent>
              {/* <DeleteAccountButton>Delete my account</DeleteAccountButton> */}
            </ProfileContainer>
          </InfoContainer>
      </MainContentContainer>
    </DashboardContainer>
  );
};

const SaveContainter = styled.div`
    margin-top: 40px;
    margin-left: 18px;
    display: flex;

    `;
 
//discard button was made with the Link properties, but link takes href, so here is being treated as a button 
const DiscardButton = styled.button`
  color: #ff746e;
  background: none;
  font-family: BR Omny, sans-serif;
  cursor: pointer;
  text-decoration: none;

  margin-top: -208px;

  @media screen and (max-width: 1024px) {
    margin-top: 40px;
   }
`;
 
const EditButtonContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 17px;
  margin-top: -28px;
  justify-content: center;
  @media (min-width: 768px) { /* Adjust for larger screens */
    flex-direction: row;
  }

  @media screen an (max-width: 768px) {
    margin-top: 10px;
  }

  @media screen and (max-width: 1024px) {
   display: none;
  }
`;
const ProfileContainer = styled.main`
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
const ProfileContent = styled.section`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const EditButton = styled.button`
  letter-spacing: -0.48px;
  color: #FF746E;
  align-self: flex-start;
  font: 500 16px BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 80px;
  margin-top: 30px;
`;
const DeleteAccountButton = styled.button`
  border-radius: 10px;
  align-self: flex-end;
  margin-top: 30px;
  color: #F93931;
  letter-spacing: -0.48px;
  padding: 16px 24px;
  font: 500 16px BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
export default SettingsPage;