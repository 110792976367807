import React from "react";
import styled from "styled-components";
import { Tab } from "@tremor/react";

function SettingsSidebar() {
  const menuItems = [
    // { id: "profile", text: "Profile Information" },
    { id: "account", text: "Account Details" },
    { id: "subscription", text: "Subscription Information" },
  ];

  return (
    <SidebarContainer>
      <SidebarMenu>
        {menuItems.map((item) => (
          <StyledTab key={item.id} value={item.id}>
            {item.text}
          </StyledTab>
        ))}
      </SidebarMenu>
    </SidebarContainer>
  );
}

const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const SidebarMenu = styled.nav`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  letter-spacing: -0.48px;
`;

const StyledTab = styled(Tab)`
  border-radius: 10px;
  padding: 12px;
  margin-top: 8px;
  text-align: left;
  cursor: pointer;
  &[data-headlessui-state~="selected"] {
    background-color: #eee;
    border: none;
     color: #000;
  }

  &:hover {
    border: none;
  }
    span {
      color: #000;
        font-size: 16px;
          font-family: "BR Omny";
    }
 
  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

export default SettingsSidebar;