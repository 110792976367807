
export const createVideo = async (userData) => {
    try { 
        const url = `${process.env.REACT_APP_CYASOON_API}v1/videos/createVideo`

     
        const formData = new FormData();
        for (const key in userData) {
          if (userData.hasOwnProperty(key)) {
            if (userData[key] instanceof File) {
              formData.append(key, userData[key]);
            } else {
              formData.append(key, String(userData[key]));
            }
          }
        }


        const response = await fetch(url, {method: "POST", headers: {
          'Accept': 'application/json',
        }, body: formData, credentials: "include" });
        const data = await response.json();
       
        return data;
      } catch (error) {
        console.log('Error post data:', error);
        return null;
      }
}

export const updateVideo = async (userData) => {
  try { 
      const url = `${process.env.REACT_APP_CYASOON_API}v1/videos/updateVideo/${userData.userId}/${userData.id}`


      const response = await fetch(url, {method: "PUT", headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        title: userData.title,
        description: userData.description,
        isActive: userData.isActive,
        expireDate: userData.date
      }), credentials: "include" });
      const data = await response.json();
     
      return data;
    } catch (error) {
      console.log('Error post data:', error);
      return null;
    }
}


export const getVideos = async (userId) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/videos/getVideos/${userId}`

    const response = await fetch(url, {method: "GET", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }});
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}


export const getVideoById = async (videoId) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/videos/getVideoObjectById/${videoId}`

    const response = await fetch(url, {method: "GET", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }});
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}


export const deleteVideoById = async (userId, videoId) => {
  try { 
    const url = `${process.env.REACT_APP_CYASOON_API}v1/videos/deleteVideo/${userId}/${videoId}`

    const response = await fetch(url, {method: "DELETE", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }});
    const data = await response.json();
   
    return data;
  } catch (error) {
    console.log('Error post data:', error);
    return null;
  }
}