import React from "react";
import styled from "styled-components";
import GoogleAutocomplete from "../../../components/UIcomponents/googleAutocomplete";

const AddressForm = ({ title, address, onSelect, onChange }) => {
  return (
    <FormContainer>
      <FormTitle>{title}</FormTitle>
      <GoogleAutocomplete onSelect={onSelect} />
      <Input
        type="text"
        placeholder="Street"
        name="street"
        value={address.street}
        onChange={onChange}
      />
      <InputRow>
        <Input
          type="text"
          placeholder="City"
          name="city"
          value={address.city}
          onChange={onChange}
        />
        <Input
          type="text"
          placeholder="State"
          name="state"
          value={address.state}
          onChange={onChange}
        />
        <Input
          type="text"
          placeholder="Zipcode"
          name="zipCode"
          value={address.zipCode}
          onChange={onChange}
        />
      </InputRow>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  width: 100%;
  max-width: 404px;
  margin-top: 39px;

  @media (max-width: 991px) {
    margin-top: 25px;
  }
`;

const FormTitle = styled.h3`
  color: #9c9c9c;
  letter-spacing: -0.42px;
  font: 400 14px BR Omny, sans-serif;

  @media (max-width: 991px) {
    font-size: 12px;
    margin-bottom: 12px;
  }
`;

const Input = styled.input`
  font-family: BR Omny, sans-serif;
  font-size: 16px;
  color: #111;
  text-align: left;
  letter-spacing: -0.48px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  padding: 3px 0;
  width: 100%;
  margin-bottom: 16px;

  &::placeholder {
    color: #9c9c9c;
  }

  &:focus {
    border-bottom: 1px solid #d9d9d9;
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 991px) {
    font-size: 14px;
    padding: 6px 0;
    margin-bottom: 12px;
  }
`;

const InputRow = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 991px) {
    gap: 12px;
    flex-wrap: wrap;
  }
`;

export default AddressForm;