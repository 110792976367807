import {  toast } from 'react-toastify';

export const startPhoneVerification = async (userPhoneData) => {
    try { 
      const verifyUrl = new URL(`${process.env.REACT_APP_VERIFY_API}web-start`);
      const url = new URL(verifyUrl)
      const params = new URLSearchParams(userPhoneData)
      url.search = params.toString()  
      const response = await fetch(url, {
        method: "GET" });
      const data = await response.json();
      if (!response.ok) {
        await toast.error(data.message || data.details, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        throw new Error(data.message || data.details);    
      }
      return data;
    } catch (error) {
      console.log('Phone Verification:', error);
      return null;
    }
  };

  export const checkPhoneVerification = async (phoneVerificationData)  => {
    try {
      const verifyUrl = new URL(`${process.env.REACT_APP_VERIFY_API}web-check`);
      const url = new URL(verifyUrl)
      const params = new URLSearchParams(phoneVerificationData)
      url.search = params.toString()  
      const response = await fetch(url, {
        method: "GET"
      });
      const data = await response.json();
    
      return data;
    } catch (error) {
      return error.message;
    }
  };
