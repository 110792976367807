export const transformToUSPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");
  
    // If the number starts with a leading '1', remove it
    let formattedNumber = digitsOnly.startsWith("1") ? digitsOnly.slice(1) : digitsOnly;
  
    // Insert the formatting characters for a US phone number
    formattedNumber = formattedNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  
    const maxLength = 14; 
    
    if (formattedNumber.length > maxLength) { formattedNumber = formattedNumber.slice(0, maxLength); }
    
    return formattedNumber;
  };