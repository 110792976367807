import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const VerificationForm = ({ verificationCode, onCodeChange }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleInput = (index, event) => {
    const value = event.target.value;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      onCodeChange(index, value);
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !verificationCode[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Form>
      <CodeInputContainer>
        {verificationCode.map((digit, index) => (
          <CodeInput
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleInput(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            aria-label={`Digit ${index + 1} of verification code`}
          />
        ))}
      </CodeInputContainer>
    </Form>
  );
};

const Form = styled.form`
  width: 100%;
      display: flex;
    justify-content: center;
`;

const CodeInputContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: 288px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
`;

const CodeInput = styled.input`
  font-family: BR Omny, sans-serif;
  font-size: 24px;
  color: #000;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.72px;
  border-radius: 10px;
  background-color: #f3f3f3;
  width: 48px;
  height: 48px;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: rgba(255, 116, 110, 1);
    background-color: #fcfcfc;
        box-shadow: none;
  }
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default VerificationForm;