import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Switch } from '@tremor/react';
import moment from 'moment';
import Loader from "../UIcomponents/Loader";
import Popup from "./Popup";

const CarouselLaunchCard = ({ onOpen, _id, title, description, expireDate, videoPhotoLink, isSelected, isActive, editAction, setItemId }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(true);
  const descriptionRef = useRef(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM DD, YYYY');
  };

  useEffect(() => {
    const img = new Image();
    img.src = videoPhotoLink;
    img.onload = () => setImageLoaded(true);
    setItemId(_id);
  }, [videoPhotoLink]);

  useEffect(() => {
    setIsTruncated(description.length > 35);
  }, [description]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const togglePopup = () => {
    setIsPopupVisible(true);
  };

  const togglePopupHide = () => {
    setIsPopupVisible(false);
  };

  const handleEditAction = () => {
    setIsPopupVisible(false);
    editAction(_id);
  };

  const handleDeleteAction = () => {
    onOpen();
    setIsPopupVisible(false);
  };

  useEffect(() => {
    if (!isSelected && isPopupVisible) {
      setIsPopupVisible(false);
    }
  }, [isSelected]);

  const truncatedDescription = isExpanded ? description : `${description.slice(0, 35)}${description.length > 35 ? '...' : ''}`;

  return (
    <Card isSelected={isSelected}>
      <ImageWrapper>
        {!imageLoaded && <Loader />}
        <BackgroundImage
          loading="lazy"
          src={imageLoaded ? videoPhotoLink : ""}
          alt={title}
          style={{ display: imageLoaded ? 'block' : 'none' }}
        />
        {isPopupVisible ? (
          <>
            <DotButton onClick={togglePopupHide}>...</DotButton>
            <Popup
              editAction={handleEditAction}
              deleteAction={handleDeleteAction}
              onClose={togglePopupHide}
            />
          </>
        ) : (
          <DotButton onClick={togglePopup}>...</DotButton>
        )}
      </ImageWrapper>
      <ContentWrapper>
        <InfoSection>
          <Label>Title:</Label>
          <Title>{title}</Title>
        </InfoSection>
        <InfoSection>
          <Label>Description:</Label>
          <DescriptionWrapper>
            <Description>{truncatedDescription}</Description>
            {isTruncated && (
              <ExpandButton onClick={toggleExpand}>
                {isExpanded ? "See Less" : "See More"}
              </ExpandButton>
            )}
          </DescriptionWrapper>
        </InfoSection>
        <InfoSection>
          <Label>Expired:</Label>
          <ExpiryDate>{formatDate(expireDate)}</ExpiryDate>
        </InfoSection>
        <InfoSection>
          <Label>Active:</Label>
          <SwitchWrapper>
            <Switch id="activeSwitch" defaultChecked={isActive} disabled />
          </SwitchWrapper>
        </InfoSection>
      </ContentWrapper>
    </Card>
  );
};

const DotButton = styled.button`
  position: absolute;
  top: 10px;
  right: 13px;
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  color: white;
  font-size: 35px;
  outline: none;
  
  &:hover {
    color: #CCCCCC;
  }

  &:focus {
    outline: none;
  }
`;

const SwitchWrapper = styled.div`
  margin-top: 8px;
`;

const Card = styled.article`
  border-radius: 40px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 630px;
  width: 260px;
  padding: 0px 0 43px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 2px solid #FF746E;
    `}
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 30px 30px 0 0;
  position: relative;
  aspect-ratio: 0.84;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px; // Fixed height to prevent shifting
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  border-radius: 40px 40px 0px 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 21px;
  flex-direction: column;
  padding: 2px 16px;
`;

const InfoSection = styled.section`
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`;

const Label = styled.h3`
  letter-spacing: -0.42px;
  color: #929292;
  font: 400 14px BR Omny, sans-serif;
`;

const Title = styled.h2`
  letter-spacing: -0.54px;
  color: #000;
  font: 600 18px BR Omny, sans-serif;
`;

const DescriptionWrapper = styled.div`
  position: relative;
  max-height: ${({ isExpanded }) => (isExpanded ? 'none' : '3.6em')};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

const Description = styled.p`
  letter-spacing: -0.48px;
  color: #000;
  font: 400 16px BR Omny, sans-serif;
  margin: 0;
  line-height: 1.2em;
  height: auto;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #ff746e;
  cursor: pointer;
  margin-bottom: 5px;
  font: 400 14px BR Omny, sans-serif;
  padding: 0;
`;

const ExpiryDate = styled.time`
  letter-spacing: -0.54px;
  color: #000;
  margin-top: 4px;
  font: 600 18px BR Omny, sans-serif;
`;

export default CarouselLaunchCard;
