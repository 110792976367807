import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ProgressDot } from "../../../components/forms/onboarding/progressDot";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/UIcomponents/Button";
import Link from "../../../components/UIcomponents/Link";
import { AppContext } from "../../../context/AppContext";
import { businessRegistration } from "../../../services/auth";
import { isJson } from "../../../utils/transformApiErrors";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const BusinessGoal = () => {
  const progressSteps = [false, false, false, false, true];
  const navigate = useNavigate();
  const [registerApiValidatorResponse, setRegisterApiValidator] = useState({});
  const { appState, updateOnboardingData } = useContext(AppContext);
  const { userData } = appState;
  const [businessGoal, setBusinessGoal] = useState(userData.businessGoal || '');
  const [cookies] = useCookies(['jwt']);

  useEffect(() => {
    if (userData.businessGoal) {
      setBusinessGoal(userData.businessGoal);
    }
  }, [userData.businessGoal]);

  const proccedBusinessUpdateRequest = async () => {
    await updateOnboardingData({
      userData: { ...userData, businessGoal }
    });

    let verificationResponse = await businessRegistration({
      businessGoal: businessGoal,
      businessCategory: userData.businessCategory,
      revenue: userData.revenue,
      legalEntityType: userData.legalEntityType,
      email: userData.email,
      ein: userData.ein,
      accountType: 0
    }, appState.userData.id, cookies.jwt);

    if (verificationResponse && verificationResponse.id) {
      await navigate('/account-confirmation');
    }

    if (verificationResponse.code === 400) {
      let validatorData;

      if (isJson(verificationResponse.message)) {
        let parsedData = JSON.parse(verificationResponse.message);
        setRegisterApiValidator(parsedData);
        validatorData = parsedData;
      } else {
        validatorData = {
          details: verificationResponse.message || "Server Error"
        };
      }

      Object.keys(validatorData).forEach((key) => {
        toast.error(validatorData[key], {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    }
  }

  const updateGoalValue = (e) => {
    setBusinessGoal(e.target.value);
  }

  const moveToRegister = async () => {
    await navigate("/register");
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
  }

  return (
    <RegistrationContainer>
      <Header>
        <Title>Business Registration</Title>
        <ProgressBar>
          {progressSteps.map((isActive, index) => (
            <ProgressDot key={index} isActive={isActive} />
          ))}
        </ProgressBar>
        <ExitButton onClick={moveToRegister}>Exit</ExitButton>
      </Header>
      <BusinessName>{userData.name}</BusinessName>
      <Question>What are your current business goals?</Question>

      <Input 
        value={businessGoal}
        onChange={updateGoalValue} 
      />   

      <Button isDisabled={!businessGoal} onClick={proccedBusinessUpdateRequest} type="button">
        Next
      </Button>
      <Link href="/business-registration/category">Back</Link>
    </RegistrationContainer>
  );
};

export const Input = styled.input`
  color: #111;
  text-align: center;
  font-family: "BR Omny";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  border-top: none;
  border-left: none;
  border-right: none;
  line-height: normal;
  border-bottom: 1px solid #D9D9D9;
  padding: 12px 15px;
  margin: 32px 0px 24px 0px;
  width: 420px;
  
  &:focus {
    outline: none;
    border-bottom: 1px solid #C2C2C2;
    box-shadow: none;
  }

  ${props => props.validation && props.itemName && props.validation[props.itemName] && `
    border: solid 3px #FC5185;
    transition: .2s;
  `}
`;

const RegistrationContainer = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 970px;
  flex-direction: column;
  align-items: center;
  padding: 44px 41px 80px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Title = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const Question = styled.p`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 17px;
  width: 412px;
  font: 400 24px BR Omny, sans-serif;
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const BusinessName = styled.h2`
  color: #ffb9b6;
  text-align: center;
  letter-spacing: -0.48px;
  margin-top: 88px;
  font: 500 16px BR Omny, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export default BusinessGoal;