import React from "react";
import styled from "styled-components";

const Logo = ({ src, alt }) => <StyledLogo src={src} alt={alt} loading="lazy" />;

const StyledLogo = styled.img`
  aspect-ratio: 2.38;
  object-fit: auto;
  object-position: center;
  width: 96px;
  height: 40px;
`;

export default Logo;