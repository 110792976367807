import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import InputField from "../../../components/UIcomponents/InputField";
import Button from "../../../components/UIcomponents/Button";
import Link from "../../../components/UIcomponents/Link";
import { useNavigate } from "react-router-dom";
import { ProgressDot } from "../../../components/forms/onboarding/progressDot";
import { AppContext } from "../../../context/AppContext";
import bcrypt from 'bcryptjs'

const AccountConfirmation = () => {
  const progressSteps = [true, false];
  const navigate = useNavigate();
  const { appState, setAppState, updateOnboardingData } = useContext(AppContext);
  const { userData, loading, error } = appState;

  const [formData, setFormData] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (userData) {
      setFormData({
        email: userData.email || "",
        confirmEmail: userData.email || ""
      });
    }
  }, [userData]);

  const validate = (name, value) => {
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    switch (name) {
      case "email":
        if (!emailRegex.test(value)) {
          newErrors.email = "Invalid email address";
        } else {
          delete newErrors.email;
        }
        if (value !== formData.confirmEmail) {
          newErrors.confirmEmail = "Email addresses do not match";
        } else {
          delete newErrors.confirmEmail;
        }
        break;
      case "confirmEmail":
        if (value !== formData.email) {
          newErrors.confirmEmail = "Email addresses do not match";
        } else {
          delete newErrors.confirmEmail;
        }
        break;
      case "password":
        if (value.length < 8) {
          newErrors.password = "Password must be at least 8 characters long";
        } else {
          delete newErrors.password;
        }
        if (value !== formData.confirmPassword) {
          newErrors.confirmPassword = "Passwords do not match";
        } else {
          delete newErrors.confirmPassword;
        }
        break;
      case "confirmPassword":
        if (value !== formData.password) {
          newErrors.confirmPassword = "Passwords do not match";
        } else {
          delete newErrors.confirmPassword;
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (formSubmitted) {
      validate(name, value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const validationErrors = validateAllFields();
    const { email, password } = formData;
    let hashedPassword = await bcrypt.hash(password, 12);
    if (Object.keys(validationErrors).length === 0) {
      await updateOnboardingData({
        userData: { ...userData, email, password: hashedPassword }
      });
      await navigate("/account-confirmation/address");
    } else {
      setErrors(validationErrors);
    }
  };

  const validateAllFields = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (formData.email !== formData.confirmEmail) {
      newErrors.confirmEmail = "Email addresses do not match";
    }

    if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  const moveToRegister = async () => {
    await navigate("/register");
    await localStorage.removeItem('appState');
    await localStorage.removeItem('onboardingData');
  }

  const isFormComplete = () => {
    return (
      formData.email &&
      formData.confirmEmail &&
      formData.password &&
      formData.confirmPassword &&
      Object.keys(errors).length === 0
    );
  }

  return (
    <StyledMain>
      <Header>
        <HeaderTitle>Account Confirmation</HeaderTitle>
        <ProgressBar>
          {progressSteps.map((isActive, index) => (
            <ProgressDot key={index} isActive={isActive} />
          ))}
        </ProgressBar>
        <ExitButton onClick={moveToRegister}>Exit</ExitButton>
      </Header>

      <h1 className="shop-name">{userData.name}</h1>
      <h2 className="instruction">Set your account details:</h2>
      <Form onSubmit={handleSubmit}>
        <InputField
          placeholder="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={formSubmitted ? errors.email : null}
        />
        <InputField
          placeholder="Confirm Email"
          type="email"
          name="confirmEmail"
          value={formData.confirmEmail}
          onChange={handleChange}
          error={formSubmitted ? errors.confirmEmail : null}
        />
        <InputField
          placeholder="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          error={formSubmitted ? errors.password : null}
        />
        <InputField
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={formSubmitted ? errors.confirmPassword : null}
        />
        <NavigationButtons>
          <Button isDisabled={!isFormComplete()} type="submit">Next</Button>
          <Link href="/account-confirmation">Back</Link>
        </NavigationButtons>
      </Form>
    </StyledMain>
  );
};

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavigationButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
  width: 130px;
`;

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const HeaderTitle = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledMain = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  max-width: 571px;
  flex-direction: column;
  align-items: center;
  padding: 44px 40px 40px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }

  .shop-name {
    color: #ffb9b6;
    text-align: center;
    letter-spacing: -0.48px;
    margin-top: 43px;
    font: 500 16px BR Omny, sans-serif;

    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }

  .instruction {
    color: #111;
    text-align: center;
    letter-spacing: -0.72px;
    margin-top: 17px;
    font: 400 24px BR Omny, sans-serif;
  }

  .back-button {
    color: #ff746e;
    margin-top: 16px;
    font: 500 16px/120% BR Omny, sans-serif;
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export default AccountConfirmation;