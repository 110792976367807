import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Link from "../../../src/components/UIcomponents/Link"
import { useNavigate } from 'react-router-dom';

function SettingsInfo({ title, accountData }) {
  const navigate = useNavigate();

  const handleForgotPassword = (e) => {
    e.preventDefault(); 
    navigate('/forgot-password', { state: { fromSettings: true } });
  };

  return (
    <InfoContainer>
      <InfoTitle>{title}</InfoTitle>
      {accountData && accountData.map((item, index) => (
        <InfoItem key={index}>
          <InfoLabel>{item.label}</InfoLabel>
          <InfoValue>{item.value}</InfoValue>
        {title === "Account Details" && item.label === "Password" && (
          <Link href="/forgot-password" onClick={handleForgotPassword}> Forgot password?</Link>
        )}
        </InfoItem>
      ))}
    </InfoContainer>
  );
}

const EditMyInformation = ({ title, name, email, phone, setBusiness, setEmail, setPhoneNumber }) => {
 
  const handleBusinessNameChange = (e) => setBusiness(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);

   return (
    <InfoContainer>
      <InfoPosition>
        <InputWrapper>
          <LabelInput>Name </LabelInput>
          <TextInput
            type="text"
            value={name}
            onChange={handleBusinessNameChange}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelInput> Email </LabelInput>
          <TextInput
            type="email"
            value={email}
            onChange={handleEmailChange}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelInput>  Phone Number </LabelInput>
          <TextInput
            type="tel"
            value={phone}
            onChange={handlePhoneNumberChange}
          />
        </InputWrapper>
      </InfoPosition>
    </InfoContainer>
  );
};

const EditBusinessInformation = ({ revenue, businessGoal, setBusinessRevenue, setBusinessGoal }) => {
  const [localRevenue, setLocalRevenue] = useState(revenue);

  useEffect(() => {
    setLocalRevenue(revenue);
  }, [revenue]);

  const handleBusinessRevenueChange = (e) => {
    let value = e.target.value;

    // Remove any non-numeric characters except periods
    value = value.replace(/[^0-9.]/g, '');

    setLocalRevenue(value); // Update the local revenue state for real-time editing
  };

  const handleBusinessGoalChange = (e) => setBusinessGoal(e.target.value);

  const handleBlur = () => {
    setBusinessRevenue(formatCurrency(localRevenue)); // Format on blur
  };

  const formatCurrency = (value) => {
    if (!value) return '';
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return '';

    return numberValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  return (
    <InfoContainer>
      <InfoPosition>
        <InputWrapper>
          <LabelInput>Current Business Revenue</LabelInput>
          <TextInput
            type="text"
            value={localRevenue}
            onChange={handleBusinessRevenueChange}
            onBlur={handleBlur} // Format on blur
            placeholder="$0.00"
          />
        </InputWrapper>
        <InputWrapper>
          <LabelInput>Business Goal</LabelInput>
          <TextInput
            type="text"
            value={businessGoal}
            onChange={handleBusinessGoalChange}
          />
        </InputWrapper>
      </InfoPosition>
    </InfoContainer>
  );
};


const InfoPosition = styled.div`
      margin-top: -12px;
  `;
const InputWrapper = styled.div`
    margin-top: 29px;
    display: flex;
    flex-direction: column;
`;
const LabelInput = styled.label`
  font-family: BR Omny, sans-serif;
  font-weight: 400px;
  font-size: 14px;
  margin-bottom: 8px; /* Space between label and input */
  color: #9E9E9E;
`;
const InfoContainer = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  letter-spacing: -0.42px;
  width: 65%;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
  }
`;
const TextInput = styled.input`
 font-family: BR Omny, sans-serif;
  border-radius: 10px;
  border: 1px solid rgba(228, 228, 228, 1);
  background-color: #FCFCFC;
  align-items:center;
  width: 333px;
  margin-top: -5px;
  color: #111;
  font-weight: 400;
  padding: 16px 7px 16px 5px;
  margin-bottom: -15px;
  height: 51px;
  box-sizing: border-box;
  gap: 8px;
  @media (max-width: 991px) {
    padding: 16px 20px 16px 16px;
`;
const InfoTitle = styled.h2`
  letter-spacing: -0.6px;
  font: 600 20px BR Omny, sans-serif;
  margin-bottom: 19px;
`;
const InfoItem = styled.div`
  margin-bottom: 16px;
`;
const InfoLabel = styled.label`
  font-family: BR Omny, sans-serif;
  color: #9E9E9E;
  display: block;
  margin-bottom: 4px;
`;
const InfoValue = styled.p`
  letter-spacing: -0.48px;
  font: 16px BR Omny, sans-serif;
  margin: 0;
`;
export { SettingsInfo, EditMyInformation, EditBusinessInformation }