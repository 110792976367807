import Sidebar from '../components/dashboard/Sidebar';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title as TremorTitle, Text as TremorText } from '@tremor/react';
import { AppContext } from '../context/AppContext';
import PersonalInfo from '../components/profile/personalData';
import BusinessInfo from '../components/profile/businessInfo';

const MainContentContainer = styled.div`
  flex-grow: 1;
  padding: 48px;
  overflow-y: auto;
`;

const Header = styled.div`
  background-color: #fff;
  border-radius: 40px;
  padding: 40px;
  margin-bottom: 32px;

  @media screen and (max-width: 1500px) {
    width: 800px;
 }

 @media screen and (max-width: 1024px) {
  width: 800px;
 }

 @media screen and (max-width: 768px) {
    width: 700px;
 }
`;

const AnalyticsTitle = styled.p`
  color: #111;
  font-family: "BR Omny";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Title = styled(TremorTitle)`
  color: #000;
  font-family: "BR Omny";
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09rem;
`;

const Text = styled(TremorText)`
  color: #000;
  font-family: "BR Omny";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  letter-spacing: -0.03rem;
`;

const Container = styled.div`
  width: 1100px; 
  margin: 0 auto;
  overflow: hidden; /* Prevent resizing */
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1500px) {
    width: 800px;
 }

  @media screen and (max-width: 1024px) {
    width: 800px;
  }
  
   @media screen and (max-width: 768px) {
    width: 700px;
  }
`;


const AnalyticsTitleWrapper = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #FFF;
  margin-bottom: 32px;
`;


const DashboardContainer = styled.div`
  display: flex;
  background-color: #fcf8f4;
  min-height: 100vh;
`;


const InfoContainer = styled.div`
  border-radius: 40px;
  background-color: #fff;
  margin-top: 32px;
  width: 100%;
    padding-top: 64px;
    padding-left: 64px;
    padding-bottom: 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const ColumnLayout = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const businessCategories = [
  { value: '722511', label: '722511: Full-Service Restaurants' },
  { value: '722513', label: '722513: Limited-Service Restaurants' },
  { value: '722410', label: '722410: Bars and Nightclubs'},
  { value: '722414',  label: '722414: Drinking Places (Alcoholic Beverages)'},
  { value: '722515',  label: '722515: Brewpubs'}
]

const ProfilePage = () => {
  const { appState } = useContext(AppContext);
  const [profileData, setProfileData] = useState({});


 useEffect(() => {
      if (appState && appState.userData) {
        setProfileData(appState.userData);
      }
 }, [appState]);

  const getBusinessCategory = (val) => {
    const category = businessCategories.find(item => item.value === val);
    return category ? category.label : '';
  }

  return (
    <DashboardContainer>
      <Sidebar />
      <MainContentContainer>
      <Header>
        <Title>Profile</Title>
        <Text>View your personal information.</Text>
      </Header>

      <AnalyticsTitleWrapper>
        <AnalyticsTitle>My information</AnalyticsTitle>
      </AnalyticsTitleWrapper>

      <Container>
        
      <InfoContainer>
        <ColumnLayout>
          <Column>
            <PersonalInfo
              name={profileData.name}
              email={profileData.email}
              phoneNumber={profileData.phone}
              businessName={profileData.businessName}
            />
          </Column>
          <Column>
            <BusinessInfo
              revenue={profileData.revenue}
              entityType={profileData.legalEntityType}
              ein={profileData.ein}
              category={getBusinessCategory(profileData.businessCategory)}
              goals={profileData.businessGoal}
            />
          </Column>
        </ColumnLayout>
      </InfoContainer>
      
    
      </Container>

    </MainContentContainer>
    </DashboardContainer>
  );
};

export default ProfilePage;

