import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../context/AppContext';
import Sidebar from '../components/dashboard/Sidebar';
import LaunchCard from '../components/videoAdPitches/LaunchCard';
import Carousel from '../components/videoAdPitches/Carousel';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Title as TremorTitle, Text as TremorText } from '@tremor/react';
import Button from '../components/UIcomponents/Button';
import AdCreationForm from '../components/videoAdPitches/CreateVideoAdPitch';
import Loader from '../components/UIcomponents/Loader';
import selectPitchIcon from '../images/selected-pitch.svg'
import myPitch from '../images/myPitch.svg'

import SuccessNotification from '../components/videoAdPitches/SuccessNotification';
import { createVideo, deleteVideoById, getVideoById, getVideos, updateVideo } from '../services/video';

const Header = styled.div`
  background-color: #fff;
  border-radius: 40px;
  padding: 40px;
  margin-bottom: 32px;

  @media screen and (max-width: 1500px) {
    width: 800px;
 }

 @media screen and (max-width: 1024px) {
  width: 800px;
 }

 @media screen and (max-width: 768px) {
    width: 700px;
 }



`;

const VideoAdTitle = styled.p`
  color: #111;
  font-family: "BR Omny";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Title = styled(TremorTitle)`
  color: #000;
  font-family: "BR Omny";
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.09rem;
`;

const Text = styled(TremorText)`
  color: #000;
  font-family: "BR Omny";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  letter-spacing: -0.03rem;
`;

const ContainerRecentPitch = styled.div`
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const ContainerPastPitches = styled.div`
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  margin-top: 35px;
  flex-direction: column;
`;

const VideoTitleWrapper = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #FFF;
`;

const DashboardContainer = styled.div`
  display: flex;
  background-color: #fcf8f4;
  min-height: 100vh;
`;

const MainContentContainer = styled.main`

   padding: 48px;
   margin: 0 auto;

`;

const PastPitchesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const ArrowContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ArrowButton = styled.button`
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: #FFF; 
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SelectedPitchContainer = styled.div` 
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

const PitchesList = styled.div`
position: relative;

`; 

const AdCreationWrapper = styled.div`
  position: relative;

@media screen and (max-width: 768px) {
   width: 700px;
}
`;

const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
`;


const SelectedContainerPitch = styled.div` 
    display: flex;
    justify-content: center;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 700px;
   }
`

const NoPitchTitle = styled.p` 
color: #9D9D9D;
text-align: center;
font-family: "BR Omny";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.72px;
`

const ImgWrapper = styled.div ` 
width: 24px;
height: 24px;
` 

const LoaderContainer = styled.div` 
      display: flex;
    justify-content: center;
    align-items: center;
` 


const StyledButton = styled.button`
  align-self: stretch;
  border: none;
  border-radius: 10px;
  background-color: #fce8e4;
  color: #ff746e;
  font: 500 16px/1.2 'BR Omny', sans-serif;
  letter-spacing: -0.48px;
  padding: 16px 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffd6d1;
  }

  &:focus {
    outline: none;
  }
`;

const PitchContainer = styled.div`
  width: 1100px; 
margin: 0 auto;

@media screen and (max-width: 1500px) {
  width: 800px;
}

@media screen and (max-width: 1024px) {
width: 800px;
}

@media screen and (max-width: 768px) {
  width: 700px;
}

`

const VideoAdPitches = () => {
  const { appState } = useContext(AppContext);
  const [successDataText, setSuccessDataText] = useState('');
  const [videoDataForEdit, setVideoDataForEdit] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [creatingOrEditingAd, setCreatingOrEditingAd] = useState(false);
  const [videoPitches, setVideoPitches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchVideosLoader, setVideosLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const carouselRef = useRef(null);
  const prevUserIdRef = useRef(null);

  const [currentCardIndex, setCurrentCardIndex] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shouldShowArrows, setShouldShowArrows] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  // Function to check screen width and update arrow visibility
  const checkScreenWidth = () => {
    const width = window.innerWidth;
    console.log(width)
    if ((width <= 1024 && videoPitches.length > 3) || (width <= 800 && videoPitches.length > 2)) {
      setShouldShowArrows(true);
    } else {
      setShouldShowArrows(false);
    }
  };

  useEffect(() => {
    if (appState && appState.userData && appState.userData.id !== prevUserIdRef.current) {
      fetchVideos();
      prevUserIdRef.current = appState.userData.id;
    }
  }, [appState]);

  useEffect(() => {
    if (videoPitches.length > 0 && carouselRef.current && carouselRef.current.getSlides) {
      const slides = carouselRef.current.getSlides();
      if (slides.length > 0) {
        setSelectedCard(slides[0]);
      }
    }
    checkScreenWidth(); // Check screen width on videoPitches update
  }, [videoPitches.length]);

  useEffect(() => {
    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  const fetchVideos = async () => {
    setVideosLoader(true);
    try {
      const data = await getVideos(appState.userData.id);
      if (data) {
        data.videos.map((item) => {
          item.isSelected = false;
          return item;
        });

        setVideoPitches(data.videos);
      }
    } catch (err) {
      console.log('Error fetching videos.', err);
    } finally {
      setVideosLoader(false);
    }
  };

  const toggleCreateOrEditAd = () => {
    setCreatingOrEditingAd(!creatingOrEditingAd);
    setVideoDataForEdit(null);
  };

  const handleVideoAdAction = async (userData) => {
    if (!videoDataForEdit) {
      if (!userData.videoFile) {
        alert('Please upload a video before publishing.');
        return;
      }
    }

    try {
      setLoading(true);
      let data;
      if (videoDataForEdit) {
        data = await updateVideo({ ...userData, userId: appState.userData.id });
        await fetchVideos();
        await setSelectedCard(data.videoUpdated);
      } else {
        data = await createVideo({ ...userData, userId: appState.userData.id });
        setVideoPitches([data.newVideo, ...videoPitches]);
        setSelectedCard(data.video || data.updatedVideo);
        setSuccessDataText('Success! Your video ad is');
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      }

      setCreatingOrEditingAd(false);
      setVideoDataForEdit(null);
    } catch (error) {
      console.error('Error creating/updating video:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateCurrentIndex = () => {
    if (selectedCard) {
      const index = videoPitches.findIndex((pitch) => pitch._id === selectedCard._id);
      if (index !== -1) {
        setCurrentCardIndex(index);
      }
    }
  };

  const editVideoAd = async (id) => {
    try {
      const data = await getVideoById(id);
      if (data.videosByObjectId.length) {
        setVideoDataForEdit(data.videosByObjectId[0]);
        setCreatingOrEditingAd(true);
        updateCurrentIndex();
      }
    } catch (err) {
      console.log('Error fetching video for edit.', err);
    }
  };

  const handleDeleteAction = async ({ userId, videoId }) => {
    const data = await deleteVideoById(userId, videoId);

    if (data.videoDeleted.deletedCount === 1) {
      await setCreatingOrEditingAd(false);
      await setVideoDataForEdit(null);
      await setSelectedCard(null);
      await fetchVideos();
      await setShowSuccess(true);
      await setSuccessDataText('Your video was deleted.');
      await closeDialog();
      await setTimeout(() => setShowSuccess(false), 3000);
    }
  };

  const setCurrentIndex = () => {
    if (currentCardIndex) {
      setCurrentCardIndex(null);
    }
  };

  return (
    <DashboardContainer>
      <Sidebar />
      <MainContentContainer>
        <Header>
          <Title>Video Ad Pitches</Title>
          <Text>View, upload, and manage your Cyasoon ads.</Text>
        </Header>
        <SelectedPitchContainer>
          <VideoTitleWrapper>
            <VideoAdTitle>
              {creatingOrEditingAd
                ? videoDataForEdit
                  ? 'Edit Ad'
                  : 'Create an Ad'
                : 'Selected Pitch'}
            </VideoAdTitle>
          </VideoTitleWrapper>

          {creatingOrEditingAd ? (
            <StyledButton onClick={toggleCreateOrEditAd}>Cancel</StyledButton>
          ) : (
            <Button size="xsmall" onClick={toggleCreateOrEditAd}>
              Create Video Ad
            </Button>
          )}
        </SelectedPitchContainer>
        {fetchVideosLoader ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <PitchContainer>  
            {creatingOrEditingAd ? (
              <AdCreationWrapper> 
                <AdCreationForm
                  deleteEvent={handleDeleteAction}
                  publishEvent={handleVideoAdAction}
                  isDialogOpen={isDialogOpen}
                  onOpen={openDialog}
                  onClose={closeDialog}
                  style={{ opacity: loading ? 0.4 : 1 }}
                  initialData={videoDataForEdit}
                />
                <FormOverlay loading={loading}>
                  <Loader />
                </FormOverlay>
              </AdCreationWrapper>
            ) : (
              <PitchesList>
                <SuccessNotification successText={successDataText} isVisible={showSuccess} />
                <ContainerRecentPitch>
                  {selectedCard ? (
                    <LaunchCard editAction={editVideoAd} {...selectedCard} />
                  ) : (
                    <SelectedContainerPitch>
                      <ImgWrapper>
                        <img sizes="24px" src={selectPitchIcon} alt="Select pitch icon" />
                      </ImgWrapper>
                      <NoPitchTitle>No Selected Pitches</NoPitchTitle>
                    </SelectedContainerPitch>
                  )}
                </ContainerRecentPitch>

                <PastPitchesHeader>
                  <VideoTitleWrapper>
                    <VideoAdTitle>My Pitches</VideoAdTitle>
                  </VideoTitleWrapper>

                  {shouldShowArrows && (
                    <ArrowContainer>
                      <ArrowButton onClick={() => carouselRef.current.slickPrev()}>
                        <ChevronLeft color="#FF746E" size={24} />
                      </ArrowButton>
                      <ArrowButton onClick={() => carouselRef.current.slickNext()}>
                        <ChevronRight color="#FF746E" size={24} />
                      </ArrowButton>
                    </ArrowContainer>
                  )}
                </PastPitchesHeader>

                <ContainerPastPitches>
                  {videoPitches.length ? (
                    <Carousel
                      ref={carouselRef}
                      onEditActionClicked={editVideoAd}
                      isDialogOpen={isDialogOpen}
                      onOpen={openDialog}
                      onClose={closeDialog}
                      deleteEvent={handleDeleteAction}
                      data={videoDataForEdit}
                      onSelectCard={setSelectedCard}
                      initialSlide={0}
                      updateStateIndex={setCurrentIndex}
                      currentStateIndex={currentCardIndex}
                      carouselData={videoPitches}
                    />
                  ) : (
                    <SelectedContainerPitch>
                      <ImgWrapper>
                        <img sizes="24px" src={myPitch} alt="My pitch icon" />
                      </ImgWrapper>
                      <NoPitchTitle>No Pitches</NoPitchTitle>
                    </SelectedContainerPitch>
                  )}
                </ContainerPastPitches>
              </PitchesList>
            )}
          </PitchContainer>
        )}
      </MainContentContainer>
    </DashboardContainer>
  );
};

export default VideoAdPitches;