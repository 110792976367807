import React, {useState} from "react";
import styled, { css } from "styled-components";
import { forgotPassword } from "../../../services/auth"; // Adjust the import path
import Check from "../../../images/check.png";
import Button from "../../UIcomponents/Button.jsx";
import Link from "../../UIcomponents/Link.jsx";
import { useNavigate, useLocation } from 'react-router-dom';

const ForgetPassword = () => {
  const [loading, setLoading] = useState(false); // Add loading state
  const [emailNew, setEmail] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isEmailSuccessful, setIsEmailSuccessful] = useState(false);

  const location = useLocation();
  const fromSettings = location.state?.fromSettings;


  const validateEmail = () => {
    return emailRegex.test(emailNew);
  };
  const handleEmailChange = (e) =>{
    setEmail(e.target.value);
  }
  const disableButton = () => {
    return !validateEmail();
  }
  const handleSubmit = async (e) =>{
    e.preventDefault();
    if (disableButton()) return; // Do nothing if the email is not valid
    setLoading(true);
    // Call the API function
    const response = await forgotPassword(
      {"email": emailNew}
    );
    setLoading(false);
    if(response && response.code === 200){
      setIsEmailSuccessful(true);
    }else{
      console.error("Failed to send code");
    }
  }

 

  return (
    isEmailSuccessful ? (
      <EmailSent email={emailNew}/>
    ) : (
    <StyledMain>
      <Question>Forgot your password?</Question>
      <Description>
        We'll send a link  to the email  associated with your
        account to reset your password.
      </Description>
      <EmailInput
      type="email"
      value={emailNew}
      onChange={handleEmailChange}
      placeholder = "Enter email address"
      />
      <AlignButton>
      <Button type="submit" size="medium" isDisabled={disableButton()} onClick={handleSubmit}>
       {loading ? <Loader/> :  "Send reset link"}
       </Button>
       </AlignButton>
       <WrapText>
        {
          fromSettings ? (
            <Link href="/account">Back to Account</Link>
          ) : (
            <Link href="/login">Back to Login</Link>
          )
        }
          </WrapText>
     </StyledMain>
    )
  );
};
const EmailSent = ({ email }) => {

  const location = useLocation();
  const fromSettings = location.state?.fromSettings;


  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  const handleNavigateToSettings = () => {
    navigate("/account");
  }

  return (
    <StyledMainEmail>
      <ImageEmail src={Check} />
      <QuestionEmail>
        Email sent!
      </QuestionEmail>
      <DescriptionEmail>
        We've sent an email to <strong>{email}</strong>
      </DescriptionEmail>
      <Wrapper>
      <AlignButton>
        { 

          fromSettings ? (
            <Button type="submit" size="medium" isDisabled={false} onClick={handleNavigateToSettings}>
            Back to Settings
            </Button>
          ) : (
            <Button type="submit" size="medium" isDisabled={false} onClick={handleNavigateToLogin}>
            Back to Login
            </Button>
           
          )
        }
          </AlignButton>

       <StyledSignUpLinkEmail>
      <Link >I didn't get the email</Link>
      </StyledSignUpLinkEmail>
      </Wrapper>
    </StyledMainEmail>
  );
};

const AlignButton = styled.div`
  margin-top: 25px;
  `;
const WrapText = styled.div`
margin-top: 10px;
`;
const Wrapper = styled.div`
    margin-top: -47px;
  justify-content: center;
    align-items: center;
  `;
const ImageEmail = styled.img`
`;
const QuestionEmail = styled.p`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 2px;
  width: 412px;
  font: 600 40px BR Omny, sans-serif;
`;
const DescriptionEmail = styled.p`
  color: #111;
  font-weight: 400px;
  text-align: center;
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.48px;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const StyledSignUpLinkEmail = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center; /* Center the link horizontally */
  margin-top: -2px;
`;
const StyledMainEmail = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 571px;
  flex-direction: column;
  align-items: center;
  padding: 80px 40px 60px;
  height: 468px;
  @media (max-width: 991px) {
    padding: 70px;
    border-radius: 40px;
  }
`;
const Question = styled.p`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 20px;
  width: 314px;
  font: 600 40px BR Omny, sans-serif;
  height: 104px;
  font-weight: 600px;
`;
const Description = styled.p`
  color: #111;
  text-align: center;
  font-family: BR Omny, sans-serif;
  letter-spacing: -0.48px;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 400px
`;
const Loader = styled.div`
  border: 4px solid rgb(246 255 246 / 27%);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  left: 40%;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
const EmailInput = styled.input`
 font-family: BR Omny, sans-serif;
  border-radius: 10px;
  border: 1px solid rgba(228, 228, 228, 1);
  background-color: #FCFCFC;
  align-items:center;
  width: 400px;
  margin-top: -15px;
  color: #111;
  font-weight: 400;
  padding: 16px;
  margin-bottom: -15px;
  height: 51px;
  gap: 8px;
  @media (max-width: 991px) {
    padding: 16px 20px 16px 16px;
`;
const StyledMain = styled.main`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  height: 506px;
  width: 571px;
  flex-direction: column;
  align-items: center;
  padding: 44px 40px 80px;
  @media (max-width: 991px) {
    padding: 70px;
    border-radius: 40px;
  }
`;
export default ForgetPassword;