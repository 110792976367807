import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import EINInput from '../../../components/UIcomponents/InputEin';
import { ProgressDot } from "../../../components/forms/onboarding/progressDot"
import Button from '../../../components/UIcomponents/Button';
import Link from '../../../components/UIcomponents/Link';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';

const BusinessCompanyEIN = () => {
    const progressSteps = [false, false, true, false, false];
    const navigate = useNavigate();
    const { appState, updateOnboardingData } = useContext(AppContext);
    const { userData } = appState; // Retrieve ein from appState
    const [ein, setEinValues] = useState(userData.ein || '');
    const [isEinActive, setEinStatus] = useState(false);

    useEffect(() => {
      setEinStatus(ein.toString().length !== 10);
    }, [ein]);

    const moveToNext = async () => {
        await updateOnboardingData({
          userData: { ...userData, ein }
        });
        await navigate('/business-registration/category');
    };

    const handleEinChange = async (newEinString) => {
      await setEinValues(newEinString);
    };

    const moveToRegister = async () => {
      await navigate("/register");
      await localStorage.removeItem('appState');
      await localStorage.removeItem('onboardingData');
    };
    
    const onTypeEin = (val) => {
      setEinStatus(val.toString().length !== 10);
    };
  
    return (
      <FormContainer>
        <Header>
          <HeaderTitle>Business Registration</HeaderTitle>
          <ProgressBar>
            {progressSteps.map((isActive, index) => (
              <ProgressDot key={index} isActive={isActive} />
            ))}
          </ProgressBar>
          <ExitButton onClick={moveToRegister}>Exit</ExitButton>
        </Header>
        <CompanyName>{userData.name}</CompanyName>
        <FormTitle>What is your EIN Number?</FormTitle>
        <WhatsThisLink>What's this?</WhatsThisLink>
        <EINInput defaultEin={ein} onEinChange={handleEinChange} onInputEin={onTypeEin} />
        <Button isDisabled={isEinActive} onClick={moveToNext} type="button">
          Next
        </Button>
        <Link href="/business-registration/select-entity">Back</Link>
      </FormContainer>
    );
};

const Header = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 8px;
`;

const HeaderTitle = styled.h1`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  font: 600 14px BR Omny, sans-serif;
`;

const ExitButton = styled.button`
  color: #000;
  font: 500 16px/120% BR Omny, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const FormContainer = styled.div`
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  width: 970px;
  flex-direction: column;
  align-items: center;
  padding: 44px 41px 80px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const CompanyName = styled.div`
  color: #ffb9b6;
  text-align: center;
  letter-spacing: -0.48px;
  margin-top: 88px;
  font: 500 16px BR Omny, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FormTitle = styled.div`
  color: #111;
  text-align: center;
  letter-spacing: -0.72px;
  margin-top: 17px;
  font: 400 24px BR Omny, sans-serif;
`;

const WhatsThisLink = styled.div`
  color: #ff746e;
  text-align: center;
  letter-spacing: -0.42px;
  margin-top: 14px;
  font: 400 14px BR Omny, sans-serif;
`;

export default BusinessCompanyEIN;